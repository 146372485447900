@import "../../sass/abstracts/variables";
@import "../../styles/colors";

.exportmodal {

  .emptyList{
    color: #b2b3bc;
    padding: 8px;
    font-weight: normal;
    font-size: 14px;
  }
  .group-legend1 {
    width: 50%;
    display: block !important;
    legend {
      font-size: 1.4rem !important;
    }
  }
  .group-legend2 {
    width: 100%;
    display: block !important;
    margin-top: 8px;
    legend {
      font-size: 1.4rem !important;
    }
  }
  .cus-inline-block {
    display: inline-block;
  }
  .cus-large-5 {
    width: 40%;
  }
  .cus-left-5 {
    margin-left: 5%;
  }
  .cus-left-10 {
    margin-left: 10%;
  }

  .background_share{
    background-color: $color-white !important;

    &:nth-of-type(even) {
      background-color: $Grey-1 !important;
    }
  }
  .figma-color {
    background-color: $Grey-1 !important;
  }
  .background_share.active{
    background-color: $color-grey-4 !important;
    background-color: #D8D8FF !important;
    color: #5A5A94;
  }
  h2 {
    color: #000000;
  }

  h3 {
    font-size: 24px;
    color: $Grey-7;
    margin-top: 20px;
  }

  table {
    thead {
      background: none;
      border: none;
      display: table;
    width: 100%;
    table-layout: fixed;
    cursor: pointer;
    }

    th {
      color: $color-grey-4;
      padding: 1rem 0.5rem 1rem 0;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 14px;
    }

    tbody {
      border: none;
      tr{
        display: table;
        width: 100%;
        table-layout: fixed;
        cursor: pointer;
      }
    }
    tbody {
      display: block;
      max-height: 180px;
      overflow: auto;
  }

    td {
      color: $color-grey-6;
      font-size: 16px;
      max-width: 1px;
      padding: 1rem 0.5rem 1rem 0;
      position: relative;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .exportcheck {
    margin: 8px 0px;
  }

  .checkmark {
    top: 5px !important;
  }
}
