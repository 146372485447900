#summarisation-container {
  .no-human-edit {
    mark.humanEdit {
      background: #fff;
      color: inherit;
    }
  }
  
  .summary-header {
    font-weight: 600;
    margin-left: 1rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  .summary-text {
    margin-right: 0.5rem;
    white-space: pre-line;
    text-align: justify;
  }
  
  .editTextBg {
    height: calc(100vh - 400px);

    mark {
      display: unset;
      padding: 3px 0;
    }
  }
}
