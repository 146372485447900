@import "../../sass/abstracts/variables";
@import "../../styles/colors";

.publishmodal input[type="radio"],
.publishmodal input[type="checkbox"] {
  width: 18px;
  height: 18px;
  top: 3px;
}

.publishmodal .radio label {
  position: relative;
  bottom: 2px;
}

.publishmodal input[type="radio"] {
  top: 4px;
}

.publishmodal .wrap {
  padding: 8px 0px;
}

.publishmodal label {
  font-size: 16px;
}

.publishmodal h2 {
  color: #000000;
}

.publishmodal .questionsvg {
  float: right;
}

.publishmodal .wrap h3 {
  font-size: 24px;
  color: $color-grey-7 !important;
}

.danger{
  color:red
}

.deleteInfo {
  text-align: center;
  background: #feb446;
  padding: 15px;
  margin: 25px 0 40px 0px;
  p {
    font-size: 16px;
    margin-bottom: 0;
    color: $White;
  }
}

.publishmodal textarea{
  font-size: 1.6rem;
  // height: 92px !important;
}

.publishmodal .waveform-bar {
  ::-webkit-scrollbar {
    height: 5px !important;
    // scrollbar-width: none;
  }
}