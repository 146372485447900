@import "../../sass/abstracts/variables";
@import "../../styles/colors";

.sharemodal {

  .emptyList{
    color: #b2b3bc;
    padding: 8px;
    font-weight: normal;
    font-size: 14px;
  }   

  .background_share{
    background-color: $color-white !important;
    height: 40px;
  }
  .background_share.active{
    background-color: #D8D8FF !important;
    height: 40px;
    color: #5A5A94;
    // background-color: #F6F6F8 !important;
  }

  tbody {
    display: block;
    max-height: 175px;
    overflow: auto;
}
  thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;/* even columns width , fix width of table too*/
    cursor: pointer;
}

  h2 {
    color: #000000;
  }

  h3 {
    font-size: 24px;
    color: $Grey-7;
    // margin-top: 20px;
  }

  table {
    thead {
      background: none;
      border: none;
    }

    th {
      color: $color-grey-4;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 14px;
      padding: 1rem 0.5rem 1rem 0;
    }

    tbody {
      border: none;
    }

    td {
      color: $color-grey-6;
      // font-weight: 500;
      padding: unset;
      font-size: 16px;
      max-width: 1px;
      position: relative;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .content_share_social{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding: 2px;
  }
  .iframeVideo{
    background:#FFF;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
  .link_social {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    justify-self: center;
  }
  .link_social .link_social_element {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
    
  }
  .link_social_element p {
    text-align: center;
    font-size: 14px;
    color: #7F8090;
  }
  .select_button {
    opacity: 1;
    justify-content: center;
  }
  .rmwc-tooltip {
    // z-index: 999999 !important;
  }

  .button {
    margin-bottom: 0;
  }
}
.public-post-share-modal {
  .Backdrop {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Modal {
    width: 660px;
    // height: 100%;
    left: calc(50% - 330px);
    // overflow: unset !important;

  }
  .textarea {
    height:180px;
    // width: 80%;
    max-width:600px;
    color:#999;
    font-weight:300;
    font-size:14px;
    text-align: start;
    font-family:'Ubuntu', Helvetica, Arial, sans-serif;
    background:#fff;
    border-radius:3px;
    line-height:2em;
    border:none;
    box-shadow:0px 0px 5px 1px rgba(0,0,0,0.1);
    padding:20px;
    -webkit-transition: height 2s ease;
    -moz-transition: height 2s ease;
    -ms-transition: height 2s ease;
    -o-transition: height 2s ease;
    transition: height 2s ease;
  }
  .media_player{
    border-radius:3px;
    line-height:2em;
    border: 1px solid #ccc;
    box-shadow:0px 0px 5px 1px rgba(0,0,0,0.1);
    padding:20px;
  }
}



