.Modal {
  position: absolute;
  z-index: 500;
  background-color: white;
  width: 600px;
  border-radius: 5px;
  // border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 1.6rem;
  left: calc(50% - 300px);
  // top: 10%;
  box-sizing: border-box;
  transition: all 0.3s ease-out !important;
  // top: 0;
  // max-height: calc(90vh);
  // overflow-y: auto;

  .customClose {
    position: absolute;
    font-size: 3rem;
    right: 3rem;
    top: 3rem;
    font-weight: 300;
    cursor: pointer;
  }
}

@media (min-width: 800px) {
  .Modal {
    width: 800px;
    left: calc(50% - 400px);
  }
}

// .Modal::-webkit-scrollbar{
//   width: 3px;
// }