@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";
@import "../../sass/base/input";

.signupForm {
  position: relative;
  .rightAlign {
    text-align: end;
    margin-top: 0px;
    width: 95%;
    float: left;
  }
  .domainAlign {
    font-size: 16px;
    position: absolute;
    right: 15px;
    top: 30px;
    color: $color-grey-7;
  }
  .customInputWidth {
    width: 73%;
  }
  .signUp_btn {
    font-size: 15px;
    color: $color-white;
    border-radius: 3px;
  }
  .grid-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .topMessage {
    @include topNotification;
    span.closeButton {
      @include closeButton;
    }
  }
  .signUp {
    margin-top: 30px;
    font-size: 36px;
  }
  > div {
    position: relative;
  }
  label {
    line-height: 2;
    .questionsvg {
      position: absolute;
      right: 15px;
      top: 2px;
    }
  }
  button {
    width: 100%;
    height: 40px;
    margin-top: 40px;
  }

  .checkmark {
    top: 5px !important;
  }
}
label input[type="checkbox"] {
  height: 18px;
  width: 18px;
  position: relative;
  top: 4px;
  margin-right: 10px;
  visibility: hidden;
}

@mixin terms {
  /* display: table; */
  line-height: 1.3rem;
  font-size: 1.1rem;
  /*  text-align: left; */
  color: $color-grey-5;
}
.termsAndCondition {
  .terms {
    font-size: 16px;
    color: $color-grey-5;
    font-weight: 400;
  }
  span {
    @include terms();
    margin-right: 5px;
  }
  a {
    @include terms();
    text-decoration: underline;
  }
}

@media screen and(min-width:1024px) and (max-width: 1366px) {
  .signupForm {
    .topMessage {
      span.closeButton {
        left: 8px !important;
      }
    }
  }
}

@media screen and(min-width:1024px) and (max-width: 1150px) {
  .signupForm {
    .rightAlign {
      width: 88%;
    }
  }
}

@media screen and(min-width:1150px) and (max-width: 1260px) {
  .signupForm {
    .rightAlign {
      width: 92%;
    }
  }
}

@media screen and(min-width:1400px) and (max-width: 1550px) {
  .signupForm {
    .rightAlign {
      width: 97%;
    }
  }
}

@media screen and(min-width:1550px) {
  .signupForm {
    .rightAlign {
      width: 100%;
    }
  }
}
