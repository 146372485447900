@import "../../../sass/abstracts/variables";
@import "../../../styles/colors";

.userData_table {
  height: calc(100vh - 212px);
  overflow: auto;
  
  thead {
    background: none;
    border: none;
  }

  th {
    color: $color-grey-4;
    text-transform: uppercase;
    padding: 1.75rem 0;
    font-weight: normal;
    font-size: 14px;
    &:first-of-type{
      padding-left: 8px;
    }
    &:last-child{
      // padding-right: 15px;
    }
  }

  tbody {
    border: none;
  }

  tbody tr:nth-of-type(even) {
    background-color: $Grey-1;
  }
  td {
    color: $color-grey-6;
    font-size: 16px;
    padding: 1.75rem 0;
    max-width: 1px;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &.has-tip {
      display: table-cell !important;
      border-bottom: none !important;
      font-weight: normal !important;
      cursor: default !important;
    }
    &:first-of-type{
      padding-left: 8px;
    }
    &:last-child{
      // padding-right: 15px;
    }

    &.date {
      color: #000000;
    }
    button {
      margin: 0px;
      background: none;
      color: $color-purple-2;
      text-decoration: underline;
      border-right: 1px solid $color-purple-2;
      padding: 0px 10px;
      font-size: 14px;
      &:active {
        border: none;
        background: none !important;
      }
    }
    button:nth-of-type(1) {
      padding-left: 0px ;
    }
    button:nth-of-type(3) {
      border-right: none;
      &:hover {
        border: none;
      }
    }
    button:hover {
      background: none;
      color: $color-purple-2;
      box-shadow: none;
      transition: none;
      border-right: 1px solid #5551ff;
    }
    button:focus {
      outline: none;
      border: none;
      box-shadow: none;
      background: none;
      color: $color-purple-2;
    }
    span {
      svg {
        margin-left: 20px;
        vertical-align: middle;
        path {
          fill: $color-purple-2;
        }
      }
    }
  }
}
button:focus {
  outline: none;
  // box-shadow: none;
}
/* Modal style start */

.reveal.small.addUserPopup {
  width: 350px;
  border: none;
  border-radius: 3px;
  .row {
    padding: 0px;
  }
  .columns {
    padding-left: 8px;
    padding-right: 8px;
  }
  h5 {
    color: #000000;
    font-weight: 700;
    margin-bottom: 30px;
  }
  form {
    label {
      color: $color-grey-6;
    }
    input,
    select {
      background: $color-grey-2;
      border-radius: 3px;
    }
    .rightAlign {
      float: right;
      button {
        margin: 0px 0px 0px 20px;
        border-radius: 3px;
        padding: 8px 22px;
      }
      button.cancel {
        background: none;
        color: $color-grey-6;
        border: 1px solid $color-grey-6;
      }
      button.apply {
        background: $color-grey-2;
        color: $color-white;

        letter-spacing: 0.01rem;
      }
    }
    select {
      background-image: url("../../../assets/Icons/icon-downwards-facing-arrow.png") !important;
      background-origin: content-box !important;
      background-position: right -1rem center !important;
      background-repeat: no-repeat !important;
      background-size: 9px 6px !important;
    }
  }
}
.reveal.small.addUserPopup:focus {
  outline: none;
}
.textOverflow {
  white-space: nowrap;
}
/* .textOverflow:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
} */

/* #disableUserModal{
  border-radius: 5px;
  padding: 25px 35px;
} */
.span-hint {
  cursor: pointer;
}

.tooltip-manage-user {
  .rmwc-tooltip-inner {
    background-color: transparent !important;
  }
}

.rmwc-tooltip.tooltip-manage-user {
  // z-index: 5;
}