
@import "../../../sass/abstracts/variables";

.useraccountsettings {
    position: absolute;
    top: 70px;
    width: 100%;
}

.tab_section{
    .tabs{
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
        background: $color-white;
        border-top: 1px solid  rgba(0, 0, 0, 0.1);
        border: none;
        .tabs-title{
            a{
                font-size: 17px;
                padding: 20px 5px;
                margin: 0px 15px;
                color: $color-grey-7;
                letter-spacing: 0.01rem;
            }
        }
        .tabs-title > a:focus, .tabs-title > a[aria-selected='true']{
            background: none;
            border-bottom: 2px solid $color-purple-2;
            color: $color-purple-2;
            outline: none;
        }
    }
}
