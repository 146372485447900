@import '../../sass/abstracts/variables';
@import '../../sass/abstracts/mixins';

.display_version_bg {
  background-color: $color-grey-4 !important;
}

.grid-scroll-container {
  position: relative;
  height: calc(100vh - #{$fixed-top-height});
  overflow-y: auto;
}

.thumbNails_display_block {
  padding-top: 2rem;
  .card {
    background-color: $color-grey-4;
    border: none;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    .card-section {
      padding: 10px 0px;
      h5 {
        margin-bottom: 2rem;
        color: #fff;
        font-weight: bold;
        max-height: 90px;
        word-break: break-all;
        font-size: 20px;
        line-height: 23px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        margin-bottom: 0px;
      }
      .title_rename {
        position: relative;
        flex: 1;
      }
      // .time_block {
      //   display: flex;
      //   justify-content: space-between;
      //   margin-top: 1rem;
      //   padding-bottom: 1rem;
      //   border-bottom: 2px solid transparent;
      //   span {
      //     font-size: 16px;
      //     color: $color-white;
      //     user-select: none;
      //     -webkit-user-select: none;
      //     -ms-user-select: none;
      //   }
      // }
      .title_block {
        cursor: pointer;
        color: var(--yt-spec-text-primary);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 3;
        letter-spacing: normal;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.2rem;
        max-height: 6.6rem;
        flex: 1 1 auto;
        order: 1;
      }
      .time_block {
        margin-top: 0.5rem;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.8r;
        display: flex;
        justify-content: space-between;
        padding-bottom: 1rem;
        span {
          // font-size: 16px;
          color: $color-grey-5;
          user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
        }
      }
    }
    .overlay_vector_icon {
      position: absolute;
      top: 12.5rem;
      left: 1rem;
      color: $color-white;
    }
    .thumbnailimg {
      width: 100%;
      // border-radius: 0.5rem;
    }
    .overlay_lock_icon {
      position: absolute;
      top: 12px;
      right: 12px;
      color: $color-white;
    }
    .overlay__status {
      position: absolute;
      top: 12px;
      left: 12px;
      height: 31px;
      line-height: 1;
      color: $color-white;
      padding: 0.8rem;
      border-radius: 3px;
      font-size: 14px;
      user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      &:hover,
      &:active,
      &:focus {
        border: 0px;
      }
    }
  }
  
}

.thumbNails_display_block {
  @include breakpoint(xlarge) {
    .xlarge-2 {
      width: 16.66667%;
    }
  }
}

.thumbNails_block {
  // margin: 1rem -15px;

  .card {
    border: none;
    position: relative;
    padding-left: 15px;
    overflow: initial;
    padding-right: 15px;
  }

  .card-section {
    padding: 10px 0px;
    h5 {
      // margin-bottom: 2rem;
      color: #030303;
      font-weight: 600;
      // height: 90px;
      font-size: 1.5rem;
      line-height: 23px;
      padding-right: 0px;
      word-wrap: break-word;
    }

    .title_rename {
      position: relative;
      flex: 1;
    }

    .title_block {
      cursor: pointer;
      color: var(--yt-spec-text-primary);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      -webkit-line-clamp: 3;
      letter-spacing: normal;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2.2rem;
      max-height: 6.6rem;
      flex: 1 1 auto;
      order: 1;
    }
    .time_block {
      margin-top: 0.5rem;
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 1.8r;
      display: flex;
      justify-content: space-between;
      padding-bottom: 1rem;
      span {
        // font-size: 16px;
        color: $color-grey-5;
        user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
      }
    } 
    &:hover .button.dot {
      color: #5551ff;
    }
  }
}

// .customThumbnail {
//   .float-right {
//     float: right !important;
//   }

//   .dropdown {
//     position: relative;
//   }
// }

.thumbnailDropdown {
  // display: none;

  .button.dot {
    background: transparent;
    border: 2px solid transparent;
    border-radius: 3px;
    color: #ffffff;
  }

  .button.dot:hover {
    border-color: #5551ff;
    color: #5551ff;
  }

  .button.dot:focus {
    border-color: transparent !important;
    color: #9b99ff;
    box-shadow: 0px 0px 15px 6px rgba(155, 153, 255, 0.2) !important;
  }

  .button.dot:active {
    background-color: #7f8090;
    border-color: transparent;
    color: #ffffff;
  }
}