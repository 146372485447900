.h-popover {
  background: #1F2066;
  border-radius: 3px;
  position: fixed;
  //position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  transform: translate(-50%, -100%);
  transition: 0.2s all;
  display: flex;
  align-items: center;
}

.h-popover:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #1F2066;
}

.h-popover-item {
  color: #fff;
  cursor: pointer;
  padding: 0;
  display: flex;
  padding: 12px 12px;
}

.h-popover-item + .h-popover-item {
  margin-left: 10px;
}

.tooltip-create-clips {
  margin: 0 1rem;
  font-size: 1.6rem;
  .rmwc-tooltip-content{
    padding: 1rem;
  }
}
