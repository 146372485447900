@import "../../../../styles/colors";
@import "../../../../sass/abstracts/variables";

#layers-dropdown span {
  font-size: 18px;
  color: $color-grey-6 !important;
}

#layers-dropdown.dropdown-pane {
  width: 24rem !important;

  li {
    display: flex;
    align-items: center;

    list-style-type: none;
    padding: 0.5rem 1.5rem !important;
    cursor: pointer;
    font-family: Roboto !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

    &:hover {
      background: $color-grey-light;
      color: $color-white;

      a, span {
        color: $color-grey-7 !important;
      }
    }

    .layer-icon {
      margin: 0 0.5rem;
      margin-right: 0.8rem;

      path {
        fill: $color-grey-6;
      }
    }

    .entity-icon {
      margin: 0 0.5rem;
      margin-right: 0.8rem;
      color: $color-grey-6;

      path:nth-of-type(2) {
        fill: $color-grey-6;
      }
    }

    &:hover .layer-icon path,
    &:hover .entity-icon path:nth-of-type(2) {
      fill: $color-grey-6;
    }
  }

  .recent-layers {
    border-top: 1px solid $color-grey-4;
    border-bottom: 1px solid $color-grey-4;
    padding-left: 2rem !important;

    .recent-down-arrow {
      margin-left: 2rem;
    }
  }

  .grid-x.flex-column {
    flex-direction: column;
  }
  
  .active-layer {
    background: #CBCCD2;
    // span{
    //   color: $color-white !important;
    // }

    // svg path {
      // fill: $color-white;
      // fill: transparent;

      // &:nth-of-type(2) {
      //   fill: $color-white;
      // }
    // }
  }
}
