@import '../../../../styles/colors';
@import '../../../../sass/abstracts/variables';

.media_toolkit_container {
  background: $Grey-1;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  width: 100%;

  .contole_bnt_group {
    display: flex;
    padding: 1rem 0px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 15px;
        color: $Grey-6;
        font-weight: 500;
        user-select: none;
      }
    }

    a.button.btnActiveBg {
      background: $Grey-5;
      border: none;
      span {
        color: $White;
      }

      &:hover {
        span {
          color: $White;
        }
        svg {
          path {
            fill: $White;
          }
        }
        border: none;
      }
      svg {
        path {
          fill: $White;
        }
      }
      &:focus {
        background: $Grey-2;
      }
    }

    a.button {
      margin-bottom: 0px;
      background: #ffffff;
      border: 2px solid #e6e6ec;
      border-radius: 3px;
      box-sizing: border-box;
      color: $Grey-5;
      line-height: revert;
      margin-right: 10px;
      svg {
        polygon {
          fill: $Grey-5;
        }
      }
      &.disabled {
        &:hover {
          border: 2px solid #e6e6ec;
          box-shadow: none;
          span {
            color: $Grey-5;
          }
          svg {
            path {
              fill: $Grey-5;
            }
          }
        }
        &:active {
          background: #fff;
          svg {
            path {
              fill: $Grey-5;
            }
          }
        }
      }
    }

    .btn-timestamp {
      min-width: 120px;
      column-gap: 8px;
      height: 40px;
    }

    .time-history {
      cursor: pointer;
    }

    .font-weight-500 {
      font-weight: 500;
    }

    a.button:hover {
      box-shadow: 1.5px 1.5px 5px rgba(0, 0, 0, 0.25);
      border: 2px solid $Purple-2;
      span {
        color: $Purple-2;
      }
      svg {
        path {
          fill: $Purple-2;
        }
        polygon {
          fill: $Purple-2;
        }
      }
    }
    a.button:focus {
      border: none;
      background: $Grey-2;
      outline: none;
      box-shadow: 0px 0px 15px 5px $Purple-1;
      span {
        color: $Purple-1;
      }
      svg {
        path {
          fill: $Purple-1;
        }
        polygon {
          fill: $Purple-1;
        }
      }
    }
    a.button:active {
      border: none;
      background: $Green;
      outline: none;
      span {
        color: $White;
      }
      svg {
        path {
          fill: $White;
        }
        polygon {
          fill: $White;
        }
      }
    }
    a {
      svg {
        path {
          fill: $Grey-5;
        }
      }
    }

    a.button.small {
      width: 125px;
      height: 40px;
      padding-left: 15px;
      svg {
        position: relative;
        right: 9px;
      }
    }
    a.button.tiny {
      width: 40px;
      height: 40px;
    }
  }

  .cursor-not-allowed {
    cursor: not-allowed;
  }

  a.button.saveBtn-saving,
  a.button.saveBtn-saving:hover {
    background: $color-purple-2 !important;
    cursor: not-allowed;

    span {
      color: $color-white !important;
    }

    svg path {
      fill: $color-white !important;
    }
  }

  .toolkit-tabs {
    color: $color-purple-2;
    border-bottom: 0.3rem solid $color-purple-2;
    font-size: 1.7rem;
  }
}
.content-share-social {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 2px;
}

.link-social {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  justify-self: center;
}
.link-social .link-social-element {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
}
.link-social-element p {
  text-align: center;
  font-size: 14px;
  color: #7f8090;
}
#timestamp span,
#layers-dropdown span {
  font-size: 18px;
  color: #000;
}

#media-tab.dropdown-pane {
  width: 250px !important;
  max-height: 343px;
  overflow: auto;
  font-size: 18px;

  li {
    list-style-type: none;
    padding: 1rem 1rem !important;
    cursor: pointer;

    &:hover {
      background: $color-purple-2;
      color: $color-white;

      a,
      span {
        color: $color-white;
      }
    }
  }
}

#timestamp.dropdown-pane {
  width: 385px !important;
  max-height: 354px;
  overflow: auto;
  font-size: 18px;

  li {
    list-style-type: none;
    padding: 0.125rem 0.25rem !important;
    cursor: pointer;

    &:hover {
      background: $color-grey-light;
      color: $color-white;

      a,
      span {
        //color: $color-white;
      }
    }
  }
}

.public-share-social-modal {
  .Modal {
    width: 660px !important;
    left: calc(50% - 330px) !important;
  }
}

// Tablet devices Landscape(phones, 576px and up)
@media (min-device-width: 927px) and (max-device-width: 1800px) and (orientation: landscape) {
  .media_toolkit_container {
    .contole_bnt_group {
      a.button {
        margin-right: 8px;
      }
      .btn-timestamp {
        width: 135px;
        column-gap: 8px;
      }
      a.button.small {
        width: 100px;
      }
    }
  }
}

@media (max-width: 1800px) {
  .contole_bnt_group {
    .loader-icon {
      margin-left: -8px;
    }

    a.button.small {
      padding-left: 8px !important;
      width: 40px !important;
      min-width: 40px !important;

      &.clips-btn {
        padding-left: 0 !important;
      }

      svg {
        right: 0px !important;
      }

      span {
        display: none;
      }
    }
  }
}

@media (max-device-width: 864px) {
  .media_toolkit_container {
    width: 100%;
    overflow-x: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none;  /* Firefox */
    .contole_bnt_group {
      padding-right: 1rem;
    }
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .media_toolkit_container::-webkit-scrollbar {
    display: none;
  }
}
