@import '../../../../../styles/colors';
@import '../../../../../sass/abstracts/mixins';
@import '../../../../../sass/abstracts/variables';

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        ) !important;
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size !important;
      }
    }
  }
}

$min_width: 320px;
$max_width: 1200px;
$min_font: 13px;
$max_font: 15px;

#video-container {
  width: 640px;
  height: 365px;
  position: relative;
}

.is-blurring {
  filter: blur(4px);
  height: calc(100vh - 70px);
  height: calc((var(--vh, 1vh) * 100) - 70px);
}

.video-container {
  width: 100%;
}

.audioMain {
  border: 1px solid #f1f3f4;
  background-color: aliceblue;
}
#video-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  transition: opacity 0.3s;
  background-image: linear-gradient(
    to bottom,
    rgb(3, 113, 168) 13%,
    rgb(0, 136, 204) 100%
  );
  background-image: -o-linear-gradient(
    to bottom,
    rgb(3, 113, 168) 13%,
    rgb(0, 136, 204) 100%
  );
  background-image: -moz-linear-gradient(
    to bottom,
    rgb(3, 113, 168) 13%,
    rgb(0, 136, 204) 100%
  );
  background-image: -webkit-linear-gradient(
    to bottom,
    rgb(3, 113, 168) 13%,
    rgb(0, 136, 204) 100%
  );
  background-image: -ms-linear-gradient(
    to bottom,
    rgb(3, 113, 168) 13%,
    rgb(0, 136, 204) 100%
  );

  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.13, rgb(3, 113, 168)),
    color-stop(1, rgb(0, 136, 204))
  );
}

#video-container:hover #video-controls {
  opacity: 0.9;
}

button {
  border: 0;
  color: #eee;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}

button:hover {
  cursor: pointer;
}

#seek-bar {
  width: 360px;
}

#volume-bar {
  width: 60px;
}

.mediaPlayer_grid_aign {
  // position: relative;
  // top: 140px;
  right: 0px;
  left: 0px;
}

.video_mgn {
  .reduced_sequence_area {
    width: 150px;
    display: flex;
    align-items: center;
    margin: 0px 0px 0px 15px;
  }
}

.video_mgn {
  margin: 0 !important;
  padding-bottom: 2rem;
  padding-top: 2rem;

  .editInpt {
    padding: 0px 5px;
    // width: 560px;
    font-size: 15px;
    line-height: 28px;
    word-break: break-word;
    margin-top: 0px;
    min-height: 40px;

    @include fluid-type($min_width, $max_width, $min_font, $max_font);

    &::selection {
      background: $bg-human-selection; // Purple-3 .25 opacity
    }
  }

  .editInpt:focus {
    outline: none !important;
  }

  .summary-text {
    mark {
      line-height: 30px;
    }
  }

  .sequence_area {
    display: flex;
    align-items: center;
    margin: 0px 0px 0px 15px;
    user-select: none;
  }
  .left_blcok {
    padding: 0px 15px 0px 0px;
    .arrow_block {
      display: flex;
      .button {
        background: $White;
        border: 2px solid $Grey-3;
        border-radius: 3px;
        padding: 10px 6px;
        height: 40px;
        width: 40px;
      }
      .button:hover {
        background-color: $White;
        border: 2px solid $Purple-2;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
        svg {
          path {
            fill: $Purple-2;
          }
        }
      }
      .button:focus {
        box-shadow: 0px 0px 15px 10px #9b99ff;
        background: #f6f6f8;
        border: none;
        outline: none;
        svg {
          path {
            fill: $Purple-1;
          }
        }
      }
      .button:active {
        background: #19afa7;
        box-shadow: none;
        border: none;
        outline: none;
        svg {
          path {
            fill: $White;
          }
        }
      }
    }
    .btn_block {
      display: flex;
      .button {
        height: 40px;
        width: 40px;
        border-radius: 3px;
        margin-bottom: 0px;
      }
      .button:hover {
        background-color: $White;
        border: 2px solid $Purple-2;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
        // svg {
        //   #play_x5F_alt {
        //     path {
        //       fill: $Purple-2 !important;
        //     }
        //   }
        //   path {
        //     fill: $Purple-2;
        //   }
        // }
      }
      .button:focus {
        box-shadow: 0px 0px 15px 10px #9b99ff;
        background: #f6f6f8;
        border: none;
        outline: none;
        // svg {
        //   path {
        //     fill: $Purple-1 !important;
        //   }
        // }
      }
      .button:active {
        background: #19afa7;
        box-shadow: none;
        border: none;
        outline: none;
        svg {
          path {
            fill: $White !important;
          }
        }
      }
    }
    .content_block {
      position: relative;
      bottom: 5px;
      span {
        color: rgb(84, 86, 108);
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        vertical-align: -webkit-baseline-middle;
        letter-spacing: 0.2rem;
        font-family: 'Roboto Mono', monospace;
      }
      h6 {
        color: $Black;
        margin: 5px 0px 5px;
        font-size: 15px;
        font-weight: 700;
      }
    }
    .btn_block {
      .button {
        background: $White;
        border: 2px solid $Grey-3;
        &.speaker {
          margin-right: 15px;
        }
      }
      .no-display {
        display: none;
      }
    }
  }
}
.bg_shadow {
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  background: $White;
  border-right: 1px solid $Grey-3;
  padding: 22px 0px;
  &:hover {
    background: rgba(240, 240, 255, 0.5);
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  }
  .flex_width {
    width: max-content;
  }
}

.maximize-main {
  transition: all 0.3s ease-out;
  background-color: $Grey-2;
}

.maximize-main-player {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3rem;

  cursor: pointer;
}

.maximize-text {
  align-items: center;
  color: #30324a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.maximize-video-clip {
  width: 100%;
  text-align: center;
  background-color: #f0f0f4;
  height: 50px;
}
.maximize-player {
  font-size: 14px;
  margin-left: 7px;
  font-weight: 800;
}
.audioBackground {
  height: 280px;
  width: 100%;
  background-image: url('https://sonnantthumbnails.s3-ap-southeast-2.amazonaws.com/audio/audio-thumbnail.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
}

.audioBar {
  width: 100%;
  border: 1px solid #f1f3f4;
  background-color: #f1f3f4;
}

.progress_section {
  display: flex;
  justify-content: space-between;
}

.controlBlock {
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  height: 80px;
  padding: 15px 15px;
  background: #201f66;
  color: $White;
  svg {
  }
  .rePlay {
    // position: relative;
    // top: 10px;
    margin: 0px 5px;
    cursor: pointer;
  }
  .timer {
    position: relative;
    top: 15px;
    width: unset;
    font-size: 12px;
    margin-right: 20px;
  }
  .durationTimer {
    font-size: 12px;
    position: relative;
    top: 13px;
    left: 0px;
  }
  .progress {
    position: relative;
    top: 21px;
    margin-right: 7px;
    padding: 0px 0px;
    height: 10px;
    border-radius: 25px;
    background: #54566c;
    cursor: pointer;
    width: 100%;

    .progress-meter {
      border-radius: 25px;
      background: $Purple-2;
      height: 10px;
    }
  }
  .progress-wave {
    position: relative;
    top: 30px;
    margin-right: 7px;
    padding: 0px 0px;
    height: 3px;
    border-radius: 25px;
    background: #fff;
    cursor: pointer;
    width: 100%;

    .progress-wave-meter {
      border-radius: 25px;
      background: $Purple-2 !important;
      height: 3px;
    }
  }
  .volumeBtn {
    position: absolute;
    right: 0;
    top: 22px;
  }
  .plaBackBtn {
    background: transparent;
    border: none;
    position: relative;
    top: 2px;
    width: 70px;
    font-size: 18px;
    &:hover {
      border: none;
    }
  }
  .btnLineHeigh {
    line-height: 2.2;
    // width: 220px;
    span {
      position: absolute;
      font-size: 7px;
      margin: 14px 9px;
    }
    span.leftAlgin {
      margin: 14px 8px;
    }
  }
  .play-pause-btn {
    float: left;
    cursor: pointer;
    background: $Purple-2;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    .playSvg,
    .pauseSvg {
      fill: #fff;
      width: 40px;
      height: 40px;
      position: relative;
      top: 5px;
      left: 5px;
    }
  }
}

.transcript-auto-scroll {
  height: $player-available-height;
  overflow-y: auto;
  overflow-x: hidden;
}

.transcript-auto-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

.transcript-auto-scroll::-webkit-scrollbar {
  width: 10px;
  height: 120px;
  background-color: $Grey-3;
}

.transcript-auto-scroll::-webkit-scrollbar-track-piece {
  background-color: #ffffff;
}

.transcript-auto-scroll::-webkit-scrollbar-thumb,
.key-scroll-wrapper::-webkit-scrollbar-thumb,
.chapter-scroll-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $Grey-3;
  min-height: 50px;
}

.external_ctrols,
.maximize-main-player {
  background-color: $Grey-2;
  // height: 40px;
  // margin: -7px 0px;
  width: 450px;
  width: 100%;
  .button {
    background: none;
    width: 40px;
    height: 40px;
    margin: 0px;
    padding: 0;
  }
  button.button:hover {
    border-radius: 3px;
    border: 2px solid $Purple-2;
    svg {
      path {
        fill: $Purple-2;
      }
    }
  }
  button.button:focus {
    border: none;
    box-shadow: 0px 0px 15px 10px #9b99ff;
    background: $Grey-2 !important;
    border-radius: 4px;
    svg {
      path {
        fill: $Purple-1;
      }
    }
  }
  button.button:active {
    border: none;
    background: #19afa7 !important;
    border-radius: 4px;
    svg {
      path {
        fill: $White;
      }
    }
  }
  .pull-right {
    float: right;
  }
}

// .progress:hover .progress-meter:after {
//   opacity: 1;
// }
.progress {
  cursor: pointer;
}

.progress-meter {
  position: absolute;
  width: 0;
  height: 3px;
  border-radius: 3px 0 0 3px;
}

.progress-meter {
  background: $Purple-2;
  z-index: 1;
}
.progress-meter:after {
  position: absolute;
  top: 0;
  right: -10px;
  width: 15px;
  height: 15px;
  margin-top: -2px;
  content: '';
  border-radius: 15px;
  background: $White;
  opacity: 1;
}

.progress-meter:after {
  transform: scale(1.4);
}

.vol_ctrl_block {
  display: inline-block;
  position: relative;

  text-align: center;
  cursor: pointer;
  &:hover .volume-control {
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }

  .button.valBtn {
    background: transparent;
    border: none;

    &:hover {
      background: transparent !important;
      border: none !important;
    }
    &:active {
      background: transparent !important;
      border: none !important;
    }
    &:focus {
      background: transparent !important;
      border: none !important;
    }
  }
}

.volume-control {
  position: absolute;
  visibility: hidden;
  border: 1px solid #aaa;
  background: #dedede;
  width: 60px;
  left: -8px;
  height: 300px;
  bottom: 35px;
  box-shadow: 2.5px 2.5px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  .pluIcon,
  .minusIcon {
    position: relative !important;
    svg {
      height: 16px;
      path {
        fill: $Grey-7;
      }
    }
  }
  .pluIcon {
    left: 0px;
    top: 10px;
  }
  .minusIcon {
    top: -2px;
    left: 0px;
    svg {
      width: 12px;
    }
  }
  .range-slider {
    display: inline-block;
    width: 40px;
    position: relative;
    text-align: center;
    height: 205px;
    top: 8px;
    //max-height: 100%;
    &:before {
      position: absolute;
      top: -2em;
      left: 0.5em;
      content: '';
      color: white;
      font-size: 90%;
    }
    input[type='range'][orient='vertical'] {
      position: relative;
      margin: 0;
      height: 100%;
      width: 100%;
      display: inline-block;
      position: relative;
      writing-mode: bt-lr;
      cursor: pointer;
      -webkit-appearance: slider-vertical;
      &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        border: none;
        width: 6px;
        background: $Purple-2;
        box-shadow: inset 1px 1px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 25px;
      }
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 25px;
        height: 25px;
        opacity: 0;
      }
      &::-moz-range-track {
        border: none;
        width: 8px;
        background: $Purple-2;
        box-shadow: inset 1px 1px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 25px;
      }
      &::-ms-track {
        border: none;
        width: 8px;
        background: $Purple-2;
        box-shadow: inset 1px 1px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 25px;
        color: transparent;
        height: 100%;
      }
      &::-ms-fill-lower {
        display: none;
      }
      &::-ms-fill-upper {
        display: none;
      }
      &::-ms-tooltip {
        display: none;
      }
      &::-moz-range-thumb {
        width: 25px;
        height: 25px;
        opacity: 0;
      }
      &::-ms-thumb {
        width: 25px;
        height: 25px;
        opacity: 0;
      }
    }
  }
  .range-slider__thumb {
    position: absolute;
    left: 7px;
    width: 25px;
    height: 25px;
    line-height: 30px;
    color: #777;
    border: 3px solid #ffffff;
    background: $Purple-2;
    font-size: 50%;
    box-shadow: 2.5px 2.5px 5px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    pointer-events: none;
  }
  .range-slider__bar {
    left: 16px;
    bottom: 0;
    height: 130px;
    position: absolute;
    background: $Purple-2;
    box-shadow: inset 1px 1px 2px 2px rgba(0, 0, 0, 0.25);
    pointer-events: none;
    width: 8px;
    border-radius: 25px;
  }
}

.ct_Width {
  width: 5% !important;
}

.mediaProgressLoader {
  text-align: center;
}

.sub-menu-parent {
  position: relative;
}

video::cue {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  // font-size: 11px;
  // font-size: 1.3vw;
  font-size: 120%;
  line-height: 1.3;
}

.sub-menu {
  // visibility: hidden;
  display: none;
  opacity: 0;
  position: absolute;
  bottom: 100%;
  height: 260px;
  left: 0;
  width: 100%;
  // transform: translateY(-2em);
  z-index: -1;
  margin-left: 0px;
  margin-bottom: -5px;
  box-shadow: 3px -2px 5px -2px $Grey-6;
  border: 1px solid $Grey-4;
  border-radius: 3px;
  // transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
  //   z-index 0s linear 0.01s;
  li {
    list-style: none;
    padding: 7px 12px;
    color: $Grey-6;
  }
}

.sub-menu-parent:focus .sub-menu,
.sub-menu-parent:focus-within .sub-menu,
.sub-menu-parent:hover .sub-menu {
  // visibility: visible;
  display: block;
  opacity: 1;
  z-index: 1;
}

.sub-menu {
  background: $White;
}

.audio_mute {
  fill: #fff;
}

.transcript-auto-scroll {
  width: 100%;
  border-right: 1px solid $Grey-3;
}

.keyterms {
  // width:450px !important;
  background: #7f8090;
  text-align: center;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  // margin-top: 30px;

  span {
    // padding-left: 5px;
    color: #fff;
  }
}

.keywords {
  margin-top: 8px;
  margin-bottom: 15px;
}

.keywordBtn {
  display: flex;
  position: relative;
  justify-content: center;
  min-width: 125px;
  max-width: 260px;
  margin-right: 8px;
  line-height: 2.5rem;
  font-size: 15px;
  height: 40px;
  font-size: 1.5rem;
  border-radius: 3px;
  padding: 5px 10px;
  margin-bottom: 12px;

  .keywordval {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 170px;
    font-weight: 500;
    display: block;
  }

  &:not(.selectedKeyword):hover {
    .badge {
      background: $Purple-2;
    }
  }
  // &:focus{
  // border: 2px solid $Purple-1 !important;
  // box-shadow: 0px 0px 15px 10px #9B99FF !important;
  // background: $White !important;
  // box-sizing: border-box;
  // .badge{
  //   background: #9b99ff;
  // }
  // }
  &:active {
    background: #19afa7 !important;
    color: $White;

    .badge {
      background: $White !important;
      color: #19afa7;
    }

    .closeButton path {
      fill: $White !important;
    }
  }
}

.selectedKeyword,
.selectedKeyword:focus {
  background: $Grey-5 !important;
  color: $White !important;
  box-sizing: border-box;

  .badge {
    background: $White;
    color: $Grey-5;
  }

  .closeButton path {
    fill: $White;
  }
}

.addkeyterm {
  width: 140px !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
  &:active {
    span {
      color: #fff !important;
    }
    border: none !important;
  }
}

.badge {
  font-size: 14px;
  vertical-align: middle;
  line-height: 1.1rem;
  margin-left: 5px;
  border-radius: 3px;
  background: #7f8090;
  min-width: 16px;
  height: 20px;
  font-weight: 500;
}

.media_toolkit_container {
  @include breakpoint(xlarge) {
    .xlarge-8 {
      width: 66.66667%;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .ct_Width {
    width: 7% !important;
  }

  .video_mgn {
    .left_blcok {
      .arrow_block {
        width: 80px;
        margin: 10px 5px 10px 0px;
      }
      .btn_block {
        display: contents;
        .button {
          margin: 25px 0px 0px 10px;
          position: relative;
          top: -15px;
        }
      }
      .content_block {
        margin: 0px 5px 0px 5px;
      }
    }
  }
}

svg.playBackPause {
  background: #7f8090;
  border-radius: 50%;
  padding: 1px;
  &:hover {
    svg {
      background: $Purple-2;
      g {
        path {
          fill: $White !important;
        }
      }
    }
  }
}

mark {
  display: inline-block;
  line-height: 0em;
  padding: 12px 0px;
  background-color: rgba(85, 81, 255, 0.25);

  &.humanEdit {
    // background-color: $Purple-2;
    background-color: #8886ff;
    color: $White;
  }

  &.searchTerm {
    background: $bg-search-term;
    color: $color-white;
  }
}

mark > mark {
  padding: 0px 0px;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .controlBlock {
    .play-pause-btn {
      width: 25px;
      height: 25px;

      .playSvg {
        width: 25px;
        height: 25px;
        top: 0px;
        left: 0px;
      }
    }

    .rePlay {
      top: 0px;
      width: 25px;
      height: 25px;
    }

    .plaBackBtn {
      font-size: 14px;
      top: 0px;
      padding: 0px;
      margin: 0;
    }

    .valBtn {
      padding: 0;
      width: 25px;
      height: 25px;
      margin: 0;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}
div.closeButton {
  padding-top: 3px;
  padding-left: 8px;
  margin-left: auto;
  cursor: pointer;
  transition: all 100ms;

  svg path {
    fill: $Grey-5;
  }

  &:hover {
    transform: scale(1.1);

    svg path {
      fill: $Purple-2;
    }
  }
}

// Small devices Landscape(phones, 576px and up)
@media (max-width: 926px) and (orientation: landscape) {
  .controlBlock {
    .progress_section {
      .progress {
        height: 6px;

        .progress-meter {
          height: 6px;
        }
        ::after {
          right: -4px;
          width: 8px;
          height: 8px;
          margin-top: -1px;
        }
      }
    }

    .play-pause-btn {
      width: 25px;
      height: 25px;

      .playSvg {
        width: 25px;
        height: 25px;
        top: 0px;
        left: 0px;
      }
    }

    .rePlay {
      top: 0px;
      width: 25px;
      height: 25px;
    }

    .plaBackBtn {
      font-size: 14px;
      top: 0px;
      padding: 0px;
      margin: 0;
    }

    .valBtn {
      padding: 0;
      width: 25px;
      height: 25px;
      margin: 0;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}
div.closeButton {
  padding-top: 3px;
  padding-left: 8px;
  margin-left: auto;
  cursor: pointer;
  transition: all 100ms;

  svg path {
    fill: $Grey-5;
  }

  &:hover {
    transform: scale(1.1);

    svg path {
      fill: $Purple-2;
    }
  }
}

.navigationRow {
  width: 100%;
}

.mw-450 {
  max-width: 450px;
}

.btn-primary.w-unset {
  width: unset;
}

@media (max-width: 926px) {
  .volume-control {
    width: auto;
    padding: 5px 0px;
    right: -8px;
    left: auto;
    bottom: 30px;
    height: 20rem;

    .pluIcon,
    .minusIcon {
      position: static !important;
    }

    .range-slider {
      position: relative;
      top: 0px;
      height: 15rem;
      input:is([type='range']) {
        visibility: hidden;
      }
    }
  }
}

@media (min-width: 640px) {
  .mediaPlayer,
  .publicPlayer {
    .video-container {
      height: calc(100vh - 21.5rem);
    }
  }
}

// @supports (-webkit-touch-callout: none) {
//   @media (min-width: 640px) {
//     .mediaPlayer,
//     .publicPlayer {
//       .video-container {
//         height: calc(100vh - 260px) !important;
//       }
//     }
//   }
// }
