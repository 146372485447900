@import '~foundation-sites/scss/foundation';
// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
// @include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// @include foundation-grid;
@include foundation-flex-classes;
@include foundation-flex-grid;

@import '../src/sass/abstracts/mixins';
@import '../src/sass/abstracts/variables';
@import '../src/sass/base/base';
@import '../src/sass/base/typography';
@import '../src/sass/base/utilities';

@import '@rmwc/tooltip/tooltip.css';
@import '@material/list/dist/mdc.list.css';
@import '@rmwc/list/collapsible-list.css';
@import '@material/drawer/dist/mdc.drawer.css';

@import '@material/menu/dist/mdc.menu.css';
@import '@material/menu-surface/dist/mdc.menu-surface.css';
@import '@material/ripple/dist/mdc.ripple.css';
@import '@material/list/dist/mdc.list.css';
@import '@rmwc/icon/icon.css';
@import '@material/ripple/dist/mdc.ripple.css';
@import '@material/fab/dist/mdc.fab.css';
@import '@rmwc/circular-progress/circular-progress.css';

@import '@uppy/core/dist/style.min.css';

@import '@uppy/dashboard/dist/style.min.css';
@import '@uppy/progress-bar/dist/style.min.css';
@import '@uppy/file-input/dist/style.min.css';
@import '@uppy/informer/dist/style.min.css';
@import '@uppy/url/dist/style.css';

@import 'react-loading-skeleton/dist/skeleton.css';
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');

@mixin default-font-face {
  font-family: 'Lexend', 'Roboto', 'Arial', 'Helvetica', 'Verdana', sans-serif !important;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  @include default-font-face();
}

h1,
h2,
h3,
h4,
h5,
h6,
li span,
div *,
[class^="uppy-Dashboard-"] {
  @include default-font-face();
}

.App {
  position: relative;
  @supports (-moz-appearance: none) {
    .grid-x {
      flex-flow: initial;
    }
    .fluid.custom_grid_container {
      .thumbNails_block {
        flex-flow: row wrap;
      }
      .thumbNails_display_block {
        flex-flow: row wrap;
      }
    }
    .video-container {
      .keywordcontainer {
        flex-flow: row wrap;
      }
    }
  }
}

.button {
  &:active {
    background: $color-purple-2 !important;
    color: $color-white !important;
    border: none;
  }
  &:focus {
    box-shadow: 0px 0px 15px 5px transparent !important;
    background: $color-purple-2;
    border: 1px solid transparent !important;
  }
}

button {
  &:active {
    background: $color-purple-2 !important;
    color: $color-white !important;
    border: none;
  }
  &:focus {
    box-shadow: 0px 0px 15px 5px transparent !important;
    background: $color-purple-2;
    // border: 2px solid #f0f0f4 !important;
  }
}

.tooltip-voice {
  opacity: 1;

  .rmwc-tooltip-inner {
    background-color: #1F2066 !important;
    border-radius: 999px;
    padding: 0.33rem 1rem !important;
  }
  .rmwc-tooltip-arrow {
    right: 30% !important;
    top: calc(100% - 2px) !important;
    color: #1F2066 !important;
    border-top-color: #1F2066;
  }
}

.tooltip-view-clips,
.tooltip-summary,
.tooltip-create-clips,
.listview-tooltip {
  .rmwc-tooltip-inner {
    background-color: #5551ff !important;
    border-radius: 6px !important;
    //margin-top: 120px !important;
  }
  &.rmwc-tooltip {
    opacity: 1;
    z-index: 2;
  }
  .rmwc-tooltip-placement-left .rmwc-tooltip-arrow,
  .rmwc-tooltip-placement-leftTop .rmwc-tooltip-arrow,
  .rmwc-tooltip-placement-leftBottom .rmwc-tooltip-arrow {
    border-left-color: #5551ff !important;
    right: -19px !important;
    margin-top: 84px !important;
    border-width: 0px 0 32px 24px !important;
  }
  .rmwc-tooltip-placement-left .rmwc-tooltip-arrow {
    top: 10% !important;
  }

  .rmwc-tooltip-placement-right .rmwc-tooltip-arrow {
    top: 10% !important;
  }

  .rmwc-tooltip-placement-right .rmwc-tooltip-arrow,
  .rmwc-tooltip-placement-rightTop .rmwc-tooltip-arrow,
  .rmwc-tooltip-placement-rightBottom .rmwc-tooltip-arrow {
    border-right-color: #5551ff !important;
    left: -19px !important;
    margin-top: 84px !important;
    border-width: 0px 24px 32px 0 !important;
  }
}

// scroll
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #e6e6ec;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #706eb0;
}

// Custom style attribute Plyr

:root {
  --sonnant-purple-2: #5451F6;

  --plyr-color-main: var(--sonnant-purple-2);

  --plyr-video-background: #f0f0f4
}
