@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.grid-container.custom_grid_container {
  padding-right: 15px;
  padding-left: 15px;
}

.thumbNails_block {
  margin: 2.5rem -15px;

  .card {
    border: none;
    position: relative;
    padding-left: 15px;
    overflow: initial;
    padding-right: 15px;
    .action-dots {
      color: $color-grey-7;
    }

    .dropdown-menu {
      left: 50% !important;
      transform: translateX(-50%) !important;
      top: 100% !important;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
      padding: 0.5rem 0rem;

      .dropdown-item {
        font-size: 1.4rem;
        font-weight: initial;
        padding: 0.8rem 2.44rem;
        color: #030303;
      }
    }
    .card-section {
      padding: 10px 0px;
      h5 {
        // margin-bottom: 2rem;
        color: #030303;
        font-weight: 600;
        // height: 90px;
        font-size: 1.5rem;
        line-height: 23px;
        padding-right: 0px;
        word-wrap: break-word;
      }

      .title_rename {
        position: relative;
        flex: 1;
      }

      .title_block {
        cursor: pointer;
        color: var(--yt-spec-text-primary);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 2;
        letter-spacing: normal;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.2rem;
        max-height: 6.6rem;
        flex: 1 1 auto;
        order: 1;
        min-height: 40px;
      }
      .time_block {
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.8r;
        display: flex;
        justify-content: space-between;
        padding-bottom: 1rem;
        span {
          // font-size: 16px;
          color: $color-grey-5;
          user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
        }
      }
    }
    .overlay_vector_icon {
      position: absolute;
      top: 12.5rem;
      left: 1rem;
      color: $color-white;
    }
    .thumbnailimg {
      width: 100%;
      // border-radius: 0.5rem;
    }
    .overlay_lock_icon {
      position: absolute;
      z-index: 1;
      top: 12px;
      right: 16px;
      color: $color-white;
    }
    .overlay_collection_icon {
      position: absolute;
      z-index: 2;
      bottom: 12px;
      right: 12px;
      color: $color-white;
    }
    .overlay__status {
      position: absolute;
      z-index: 1;
      top: 12px;
      left: 12px;
      // height: 31px;
      line-height: 1;
      color: $color-white;
      padding: 0.9rem;
      border-radius: 3px;
      font-size: 14px;
      background-color: #5551ff;
      color: #ffffff;
      user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      font-weight: 500;
      &:hover,
      &:active,
      &:focus {
        border: 0px;
      }
    }
  }

  .sub-item-simpleMenu {
  }

  .videoThumbnail {
    .thumbnailDropdown {
      display: block;

      .button.dot {
        background: #fff;
        border: 2px solid transparent;
        border-radius: 3px;
        color: #5551ff;
      }

      .button.dot:hover {
        border-color: #5551ff;
        color: #5551ff;
      }

      .button.dot:focus {
        border-color: transparent !important;
        color: #9b99ff;
        box-shadow: 0px 0px 15px 6px rgba(155, 153, 255, 0.2) !important;
      }

      .button.dot:active {
        background-color: #7f8090;
        border-color: transparent;
        color: #ffffff;
      }
    }

    &:hover .thumbnailDropdown {
      display: block;
    }
  }
}

.arrow-button {
  width: 36px;
  height: 36px;

  text-align: center;

  display: flex;

  position: relative;
  right: 0;
  // top: 35px;

  cursor: pointer;
  border: 2px solid transparent;
  // margin-bottom: 30px;
}

.arrow-button:hover {
  border: 2px solid #5551ff;
  border-radius: 10%;
}

.arrow-style {
  margin: auto;
}

.summary-container{
  transition: all .5s ease-in-out;
  height: 0;
  z-index: 4;
}

.summary-container.is-active{
  height: auto;
  max-height: 240px;
}

.summary-container::-webkit-scrollbar{
  width: 6px;
}

div.empty-tooltip .rmwc-tooltip-content {
  margin-top: 21px;
}

.empty-tooltip {
  margin-top: 100px;
}

.thumbNails_block {
  @include breakpoint(xlarge) {
    .xlarge-2 {
      width: 16.66667%;
    }
  }
}
.image-container {
  position: relative;
  cursor: pointer;
}

.content-overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  border-radius: 0.5rem;
}

.image-container:hover .content-overlay {
  opacity: 1;
}
.image-container.active .content-overlay {
  opacity: 1;
}

.middle {
  opacity: 1;
  text-align: center;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customThumbnail {
  .float-right {
    float: right !important;
  }

  .dropup,
  .dropright,
  .dropdown,
  .dropleft {
    position: relative;
  }

  .dropdown-toggle {
    white-space: nowrap;
  }

  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  .dropdown-toggle:empty::after {
    margin-left: 0;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    // min-width: 10rem;
    width: fit-content;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }

  .dropdown-menu-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-right {
    right: 0;
    left: auto;
  }

  .dropdown-menu.show {
    display: block;
  }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #5551ff;
    color: #ffffff !important;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #5551ff;
  }

  .dropdown-item.disabled,
  .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent;
  }

  .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
}
