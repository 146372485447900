@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";
@import "../../sass/base/input";

.topMessage {
  @include topNotification;
  span.closeButton {
    @include closeButton;
  }
  &.error {
    position: sticky;
    top: 0;
    z-index: 20;
  }
}
.signinForm {
  position: relative;
  // top: 50px;
  padding-top: 50px;
  padding-bottom: 5rem;
  button {
    width: 100%;
    height: 41px;
    border-radius: 3px;
    color: $color-white;
    font-size: 15px;
  }
  .signIn {
    font-size: 36px;
    margin-top: 30px;
  }

  .forgetPassword {
    margin-top: 2rem;
    text-decoration: underline;
    font-size: 1.6rem;
    line-height: 1.9rem;
    text-align: center;
  }
}
.grid-container.signInBtn {
  padding-left: 15px;
  padding-right: 15px;
}

.forgotPassword {
  font-size: 16px;
  color: $color-grey-7;
}
.small.reveal.resetPasswordPopup:focus {
  outline: none;
}
.small.resetPasswordPopup {
  // width: 45rem;
  // padding: 20px;
  // border-radius: 3px;
  .resetHeader {
    font-weight: 700;
    font-size: 30px;
    color: #000000;
    margin-bottom: 15px;
  }
  input {
    height: 5rem;
  }
  .close-button {
    font-size: 30px;
    right: 1.5rem;
    top: 1.8rem;
    background-color: transparent;
  }
  .rightAlign {
    float: right;
    margin-top: 15px;
  }
}
