
  
 /* ======= Toast message ======== */
  .toast {
    transition: all linear 0.3s;
  }

  .toast + .toast {
    margin-top: 50px;
  }
  
  @keyframes slideInLeft {
    from {
      opacity: 0;
      transform: translateX(calc(100% + 32px));
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }
  
  .toast--success {
    border-color: rgb(25, 175, 167) !important;
  }
  
  .toast--success {
    .toast__icon {
        svg {
            color: rgb(25, 175, 167) !important;
            fill: rgb(25, 175, 167) !important;
        }
    }
  }
  
  .toast--info {
    border-color: #2f86eb !important;
  }
  
  .toast--info {
    .toast__icon {
        svg {
            color: #2f86eb !important;
            fill: #2f86eb !important;
        }
    }
  }
  
  .toast--warning {
    border-color: #ffc021 !important;
  }
  
  .toast--warning {
    .toast__icon {
        svg {
            color: #ffc021 !important;
            fill: #ffc021 !important;
        }
    }
  }
  
  .toast--error {
    border-color: #ff4b4b !important;
  }
  
  .toast--error {
    .toast__icon {
        svg {
            color: #ff4b4b !important;
            fill: #ff4b4b !important;
        }
    }
  }
  