@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";
@import "../../sass/base/input";

.sessionexpiry {
  margin-top: 3rem;
  p {
    color: #000;
    font-size: 20px;
    font-weight: 400;
  }
  h3 {
    font-size: 26px;
    font-weight: 700;
    color: #000;
  }

  .flex {
    display: flex;
  }
  .btn_section {
    float: right;
    margin-top: 1rem;
    button {
      margin-right: 10px;
    }
  }
}

.block {
  display: block !important;
}

.modalSignIn {
  button {
    width: 100%;
    height: 40px;
    background: $color-purple-2;
    border-radius: 3px;
    color: $color-white;
    margin-top: 30px;
    font-size: 15px;
    &:active {
      background: $color-purple-2 !important;
      color: $color-white !important;
      border: none;
    }
    &:focus {
      box-shadow: 0px 0px 15px 5px transparent !important;
      background: $color-purple-2;
      border: 2px solid #f0f0f4 !important;
    }
  }
  .signIn {
    font-size: 36px;
    margin-top: 30px;
  }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .SignInSignUp {
    .Backdrop {
      backdrop-filter: blur(.3em);
      -webkit-backdrop-filter: blur(.3em);
    }
  }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .SignInSignUp {
    .Backdrop {
      background-color: rgba(0, 0, 0, .85);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);      
    }
  }
}
