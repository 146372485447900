@import "../../../sass/abstracts/variables";

hr {
  margin: 10px 0px;
}
h4 {
  margin-bottom: 30px;
}

h2 {
  font-size: 30px;
  font-weight: 700;
}

h3 {
  font-size: 20px;
  font-weight: 700;
}

h6 {
  color: $color-grey-7;
  font-size: 16px;
  font-weight: 600;
}
.freePlan {
  display: flex;
  justify-content: space-between;

  button {
    padding: 5px 20px;
    background: $color-purple-2;
    color: $color-white;
    font-size: 14px;
    border-radius: 25px;
  }
  h6 {
    color: $color-grey-7;
    font-size: 20px;
    font-weight: 600;
  }
}

.maxUser {
  margin-bottom: 30px;
  h6 {
    color: $color-grey-7;
    font-size: 24px;
    font-weight: 600;
  }
  span {
    font-size: 12px;
    color: $color-grey-5;
  }
}
.features {
  fieldset {
    label {
      color: $color-grey-7;
      font-size: 16px;
      padding-left: 15px;
    }
  }
}
.borderedCol {
  border-left: 1px solid $color-grey-3;
  border-right: 1px solid $color-grey-3;
  padding: 0px 20px;
}
.colPosition,
.borderedCol {
  position: relative;
}
.upGradePlan {
  position: relative;
  bottom: -70px;

  button {
    width: 100%;
    background: $color-purple-2;
    color: $color-white;
    border-radius: 3px;
    font-size: 15px;
  }
}
.couponCode {
  float: right;
  button {
    background: none;
    color: $color-grey-6;
    border: 1px solid $color-grey-6;
    border-radius: 3px;
    font-size: 14px;
    padding: 11px 6px;
    position: relative;
    margin-bottom: 0px;
    top: 25px;
  }
}

.billing-wrapper {
  max-height: calc(100vh - #{$fixed-top-height});
  overflow-y: auto;
}
