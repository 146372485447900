@import "../../../styles/colors";

.successNotification {
  margin-top: 30px;
  position: relative;
  top: 70px;
  .mgn-auto {
    margin: auto;
  }
  h1 {
    font-size: 32px;
    line-height: 5.2rem;
    font-weight: bold;
    color: #000000;
  }
  h3 {
    color: $Grey-7;
    font-weight: 700;
    margin-top: 15px;
    font-size: 24px;
  }
  .notificationContainer {
    text-align: center;
    margin-bottom: 50px;
  }
  button {
    width: 100%;
    height: 40px;
    font-size: 15px;
  }
}
