@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.changePasswordForm {
  position: relative;
  top: 70px;
  label {
    font-size: 1.4rem;
  }
  .topMessage {
    @include topNotification;
    span.closeButton {
      @include closeButton;
    }
  }
  input {
    height: 40px;
    font-size: 1.6rem;
    background: $color-grey-2;
    border-radius: 3px;
  }

  button {
    border-radius: 3px;
    min-width: 100px;
  }
  .applywrapper {
    margin-top: 30px;
  }
  .changeHeader {
    font-weight: 700;
    font-size: 36px;
    color: #000000;
    margin: 15px auto;
  }
  .confirmButton {
    color: $color-white;
    background-color: $color-purple-2;
    width: 100%;
    height: 40px;
    font-size: 1.5rem;
    &:disabled {
      background-color: $color-grey-5;
    }
  }

  .cancelButton {
    background-color: $color-white;
    border: 2px solid $color-white-1;
    color: $color-grey-6;
  }
}
