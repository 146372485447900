@import "../../styles/colors";
@import "../../sass/abstracts/variables";

.top-bar{
  position: unset !important;
}

.title_part {
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  position: fixed;
  top: $toolbar-height;
  width: 100%;
  background: #fff;
  // z-index: 1;
  svg {
    margin-right: 10px;
    margin-bottom: 5px;
  }
  h3 {
    font-size: 30px;
    font-weight: bold;
    color: $Grey-7;
  }
}

.libraryview {
  position: relative;
  top: 340px;
}
