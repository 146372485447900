@import "../../../sass/abstracts/variables";
@import "../../../sass/base/input";

.grid-container.fluid{
  padding-left: 15px;
  padding-right: 15px;
}
.btnDisabled {
  pointer-events: none;
}
.tenantProfile {
  .tenantTitle {
    margin: 20px 0px;
    h2 {
      color: #000000;
      font-weight: 700;
      font-size: 30px;
      letter-spacing: 0.05rem;
    }
  }
  .btnReset {
    float: right;
    button {
      margin: 15px 0px 0px 15px;
      box-sizing: border-box;
      border-radius: 3px;
      font-size: 15px;
      height: 40px;
      width: 125px;
    }
    .reset {
      background: $color-white;
      border: 2px solid $color-grey-3;
      color: $color-grey-6;
      &:active{
        color: $color-white !important;
      }
    }
    .btn-Disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    .apply {
      background: $color-purple-2;
      color: $color-white;
      letter-spacing: 0.01rem;
    
      &:focus{
        background: $color-purple-1;
        border: 2px solid $color-grey-2;
        box-shadow: 0px 0px 15px 10px #9B99FF;
        color: $color-white;
      }
      &:active{
        background: #19AFA7;
        box-shadow: none;
        border: none;
        color: $color-white !important;
      }
    }
  }
}
.tenantProfile_picture {
  h3 {
    color: $color-grey-7;
    font-weight: 700;
    font-size: 20px;

    letter-spacing: 0.05rem;
  }
  .mgn_btm {
    margin-bottom: 20px;
  }
  .paramount {
    margin: 0px 10px 0px 0px;
  }
  .paramount_title {
    font-weight: 300;
    display: inline;
    text-decoration: underline;
    cursor: pointer;
    color: $color-purple-2 !important;
    font-size: 1.8rem;
    vertical-align: middle;
  }

  .noImage {
    display: none;
  }

  .tenantProfile {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  form {
    label {
      color: $color-grey-6;

      letter-spacing: 0.01rem;
      .domain {
        color: $color-grey-7;
        margin-bottom: 10px;
        font-size: 16px;
      }
      input,
      textarea,
      select {
        background: $color-grey-2;
        font-size: 16px;
      }
      textarea {
        height: 100px;
        border: none;
      }

      select {
        background-image: url("../../../assets/Icons/icon-downwards-facing-arrow.png") !important;

        background-origin: content-box !important;
        background-position: right -1rem center !important;
        background-repeat: no-repeat !important;
        background-size: 9px 6px !important;
      }
    }
  }
}
