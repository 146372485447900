@import "../../sass/abstracts/variables";
.row_bg {
  background-color: $color-grey-1;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 5px 15px;  
  width: 100%;
}
.button-group {
  margin: 5px 3px;
  .button {
    margin-bottom: 0;
  }
}
.sort_modify {
  a.button.dropdown:after {
    border-color: #54566c transparent transparent;
  }
  a.button.dropdown {
    padding: 0 12px;
    width: 40px;
    height: 40px;
  }
  .button {
    height: 40px;
    width: 200px;
    line-height: 2.5;
    font-size: 14px;
    padding: 1px 20px;
    background: #ffffff;
    border-radius: 3px;
    border: 2px solid #e6e6ec;
    color: #54566c;
  }
  .button-arrow {
    height: 40px;
    width: 40px;
    line-height: 2.5;
    font-size: 14px;
    padding: 0px 3px;
    background: #ffffff;
    border-radius: 3px;
    border: 2px solid #e6e6ec;
    color: #54566c;
  }
}
.sor_group {
  button {
    // margin: 0 10px 0 0;
    margin-right: 1rem;
    box-sizing: border-box;
    border-radius: 3px;
    height: 40px;
    width: 40px;
    background: $color-white;
    border: 2px solid #e6e6ec;
  }
  .button:hover {
    box-shadow: 1.5px 1.5px 5px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    border: 2px solid $color-purple-2;
    background: $color-white;
    svg {
      path {
        fill: $color-purple-2;
      }
    }
  }
  .active {
    background: $color-grey-5;
    border: 2px solid #e6e6ec;
    svg {
      path {
        fill: $color-white;
      }
    }
  }
  button.button:focus {
    background-color: $color-grey-2;
    border: none;
    outline: none;
    svg {
      path {
        fill: $color-purple-1;
      }
    }
  }

  .list {
    svg {
      color: $color-grey-5;
      height: 1.7rem;
      width: 1.4rem;
    }
  }
  .thumb {
    svg {
      color: $color-white;
      height: 1.7rem;
      width: 1.4rem;
    }
  }
}

.align-right {
  float: right;
  button.select {
    margin: 5px 8px;
    height: 40px;
    width: 125px;
    border-radius: 3px;
    background: #5551ff;
    color: #fff;
    font-size: 14px;
  }
  button.cancel {
    margin: 5px 8px;
    height: 40px;
    width: 125px;
    border-radius: 3px;
    background: #7f8090;
    color: #fff;
    font-size: 14px;
  }
}
.reset_block {
  a.button {
    &:hover {
      background: #5551ff;
    }
  }
  a.button.dropdown:after {
    border-color: #ffffff transparent transparent;
    margin-left: 10px;
  }
  .button {
    height: 40px;
    line-height: 2.5;
    font-size: 14px;
    padding: 1px 15px;
    background: #5551ff;
    border-radius: 3px;
    color: #ffffff;
  }
}

.manageDropDown {
  list-style-type: none;
  background-color: $color-white;
  border: 1px solid $color-grey-2;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 5px 5px;
  margin-top: 0px;
  li {
    &:hover {
      background: $color-grey-light;
      a {
        color: $color-grey-7;
      }
    }
    &:focus {
      background: $color-purple-1;
      box-shadow: 0px 0px 15px 10px #9b99ff;
      border: 1px solid $color-purple-1;
      a {
        color: $color-white;
      }
    }
    &:active {
      background: #5551ff;
      a {
        color: $color-white;
      }
    }
  } 
  li a {
    font-size: 14px;
    letter-spacing: 0.01rem;
    line-height: 2.1rem;
    color: $color-grey-7;
  }
  li.disabled{
    
    &:hover {
      background: none;
      a {
        cursor:not-allowed;
        color: black;
      }
    }
  }
}

.dropbtn {
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  left: -13px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-sort-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  left: -12px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 3;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;

  text-decoration: none;
  display: block;
}

/* Show the dropdown menu on hover */
.dropdown.active:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropdown-sort-content {
  display: block;
}
.drop-arrow {
  padding: 10px !important;
}
.sort-icon {
  cursor: pointer;
  padding-left: 15px;
  display: flex;
  align-items: center;

  svg {
    height: 40px;
    width: 40px;
  }
}

.color-grey {
  background-color: #7f8090 !important;
}

.menu.align-right li {
  justify-content: flex-start;
}

.menu .active > a {
  background: #5551ff;
}


.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgb(127, 128, 144);
  top: 2px;
  position: relative;
  vertical-align: text-top;
  margin-left: 10px;
}
.manage_library{
  .arrow-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $color-white;
    top: 2px;
    position: relative;
    vertical-align: text-top;
    margin-left: 10px;
  }
}
.dropdown-pane{
  padding: 1rem 0;
  width: 200px
}