@import '../../sass/abstracts/variables';
@import '../../sass/abstracts/mixins';
@import '../../styles/colors';

.display_version_list_bg {
  background-color: $color-grey-4 !important;
}

tr.display_version_list_bg td {
  color: $color-white !important;
}

.display_version_list_view {
  position: relative;
  z-index: 0;

  .list_display_block {
    .userData_table {
    //   thead {
    //     background: #fff;
    //     th {
    //       padding: 15px 0px;
    //     }
    //   }

    //   tr {
    //     cursor: pointer;
    //     border-bottom: 1px solid $Grey-3;
    //   }
    //   td {
    //     white-space: nowrap;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //     &:first-of-type {
    //       padding-left: 15px;
    //     }
    //     &:last-child {
    //       padding-right: 15px;
    //     }
    //   }
    //   th:first-of-type {
    //     padding-left: 15px;
    //   }
    //   th:last-child {
    //     padding-right: 15px;
    //   }
    
    }
  }
  @media screen and (min-width: 40em) {
    .list_block {
      .cell.grid-padding-x.medium-12 {
        padding-right: 5px;
        padding-left: 5px;
      }
    }
  }

  // .action-item {
  //   overflow: hidden;
  //   white-space: nowrap;
  //   text-overflow: ellipsis;
  // }

  // .select_button {
  //   opacity: 0;
  // }

  // .list-container:hover .select_button {
  //   opacity: 1;
  // }
  // .list-container.active .select_button {
  //   opacity: 1;
  // }

  table {
    thead {
      background: #fff;
      tr {
        background: white;
        position: -webkit-sticky;
        position: sticky;
        z-index: 1;
        top: -1px;
      }
      th {
        color: #b2b3bc;
        text-transform: uppercase;
        font-weight: normal;
        font-size: 14px;
        padding: 1.5rem 0.5rem 1.5rem 0;
        &:first-of-type {
          padding-left: 15px;
        }
        &:last-child {
          padding-right: 15px;
        }
      }
    }
    tbody {
      .dropdown-menu {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
          0 10px 10px rgba(0, 0, 0, 0.22);
        padding: 0.5rem 0rem;

        .dropdown-item {
          font-size: 1.4rem;
          font-weight: initial;
          padding: 0.8rem 2.44rem;
          color: #030303;
        }
      }
      h5 {
        // margin-bottom: 2rem;
        color: #030303;
        // height: 90px;
      }
      .title_block {
        cursor: pointer;
        // color: var(--yt-spec-text-primary);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 1;
        letter-spacing: normal;
        max-height: 2rem;
      }
      td {
        padding: 0.6rem 0;
        padding-right: 0.5rem;
        color: #54566c;
        font-size: 1.5rem;
        position: relative;
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        max-width: 1px;
        &:first-of-type {
          padding-left: 15px;
        }
        &:last-child {
          padding-right: 15px;
        }
      }
      .action-item {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .button.dot{
        background: #fff;
        border: 2px solid #E6E6EC;
        border-radius: 3px;
        color: #7F8090;
      }
    }
  }
}

.button.dot.active{
  border-color: #5551FF;
  color: #5551FF;
}
three
.button.dot:hover{
  border-color: #5551FF;
  color: #5551FF;
}      

.button.dot:focus{
  border-color: transparent !important;
  color: #9B99FF;
  box-shadow: 0px 0px 15px 6px rgba(155, 153, 255, 0.2) !important;
}

.button.dot.active:focus{
  border-color: #5551FF !important;
  color: #5551FF;
}

.button.dot:active{
  background-color: #7F8090 !important;
  border-color: transparent;
  color: #FFFFFF;
  box-shadow: none !important;
}     
