.keywordEdit {
  display: flex;
  height: unset;
  padding: 0;
  border: none;
  box-sizing: border-box;

  background: #fff !important;
  border-radius: 3px;

  :hover:not(:disabled) {
    border: none;
  }

  .keywordInput {
    margin: 0;
  }

  .cancelBtn {
    height: 4rem;
  }
}

.whiteIcon path {
  fill: #fff;
}
