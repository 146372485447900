@import '../../sass/abstracts/variables';

.top-bar {
  background-color: $color-purple-4;
  box-shadow: inset 0px -1px 0px #f0f3f6;
  height: 70px;
  padding: 0px 15px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  .top-bar-left {
    ul {
      li {
        margin-right: 20px;
        a {
          padding: 0px;
        }
      }
    }
  }
  ul {
    background-color: inherit;
    align-items: center;

    li {
      letter-spacing: 0.1px;
      .sonnantLogo {
        width: 5rem;
      }

      > svg {
        margin-bottom: 4px;
        margin-right: 4px;
      }

      > a {
        display: inline;
        color: $color-white;
        padding: 0.7rem 1.5rem;
        font-size: 20px;
      }

      > a.breadcrumbs {
        display: inline-block;
        text-decoration: underline !important;
        color: #30324a !important;
        font-weight: bold;
      }

      > a.active {
        color: $color-purple-2;
      }
      > a.underline {
        border-bottom: 3px solid $color-purple-2;
        border-radius: none !important;
      }
      &.menu-text {
        font-size: 5rem;
        color: $color-white;
        padding: 0px;
        a {
          padding: 0;
        }
        span.componayName {
          vertical-align: text-top;
          margin-left: 1rem;
          font-weight: normal;
          position: absolute;
          font-size: 4rem;
        }
      }
    }
  }
  &.white {
    background-color: $color-white;
    a {
      color: $color-grey-7;
    }
  }
  .topleft {
    height: inherit;
  }
  .SignInSignUp {
    justify-content: flex-end;
    height: inherit;
    // column-gap: 15px;
    margin: 0px 10px 0px 10px;
    li {
      display: flex;
      height: inherit;
      a {
        margin: auto 0px;
      }
      p {
        color: $color-white;
        font-size: 1.8rem;
        margin-bottom: 0px;
        height: inherit;
        text-overflow: clip;
        overflow: hidden;
        white-space: pre-wrap;
        margin-top: 15px;
      }
    }
    .search-container {
      display: flex;
      margin-right: 15px;
    }
    .right-menu-container {
      display: flex;
      // column-gap: 15px;
      > button {
        margin-right: 15px;
      }

      .btn-signup {
        margin-right: 0px;
      }

      .dropdown.menu {
        ul li a {
          width: 100%;
        }
      }
    }
    .search-row {
      position: relative;
      // width: 300px;

      .input-search {
        // min-width: 30rem;
        border-radius: 5px 0px 0px 5px !important;
      }
    }
    .close-button {
      right: 12px;
      top: 7px;
      font-size: 24px;
      display: none;
    }
    .show {
      display: block;
    }
    .searchSpan,

    .bellSpan {
      border: 2px solid $color-grey-3;
      padding: 0;
      border-radius: 3px;
      text-align: center;
      svg {
        vertical-align: middle;
        width: 2rem;
        height: 2rem;
        fill: $color-grey-7;
      }
    }
    .searchSpan {
      margin: 0px;
    }
    .upload {
      font-weight: 500;
      display: flex;
      padding: 12px 15px;
      justify-content: space-around;
      align-items: center;
      border-radius: 3px;
      background-color: $color-purple-2;
      &:focus {
        background: $color-purple-1;
        box-shadow: 0px 0px 15px 10px #9b99ff;
        border: 2px solid $color-grey-2;
      }
      &:active {
        span {
          color: $color-white;
        }
        border: none;
      }
    }
    .avatar {
      display: inline;
      // height: 100%;
      // width: inherit;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      position: relative;
      vertical-align: middle;
      object-fit: cover;
    }
    .accountDropDown {
      background-color: $color-white;
      border: 1px solid $color-grey-2;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
      border-radius: 0px 0px 5px 5px;
      z-index: 999999;

      li {
        font-size: 1.8rem;
        letter-spacing: 0.01rem;
        line-height: 2.1rem;
        color: $color-grey-7;

        &:hover {
          background: $color-grey-light;
          a {
            color: $color-grey-7;
          }
        }
        &.disabled-link {
          a {
            cursor: not-allowed;
            opacity: 0.3;
            color: #000;
          }
          &:hover {
            background: none;
          }
        }

        &:focus {
          background: $color-purple-1;
          box-shadow: 0px 0px 15px 10px #9b99ff;
          border: 1px solid $color-purple-1;
          a {
            color: $color-white;
          }
        }
        &:active {
          background: #19afa7;
          a {
            color: $color-white;
          }
        }
      }
      li a {
        padding: 1.5rem;
        // font-size: 1.8rem;
        // letter-spacing: 0.01rem;
        // line-height: 2.1rem;
        // color: $color-grey-7;
        // padding: 1.5rem;
      }
    }

    .SignIn {
      font-size: 15px;
      border-radius: 3px;
      margin-right: 10px;
      // min-width: 12.5rem;
      text-align: center;
      background: $color-purple-2;
      color: $color-white;
      min-width: auto;
      &:active {
        background: $color-purple-2 !important;
        color: $color-white !important;
        border: none;
      }
      &:focus {
        box-shadow: 0px 0px 15px 5px transparent !important;
        background: $color-purple-2;
        border: 2px solid #f0f0f4 !important;
      }
      //line-height: 3rem;
      // &:focus{
      //   box-shadow: 0px 0px .5rem 0.5rem $color-purple-1;
      // }
    }

    .SignUp {
      min-height: 5rem;
      font-size: 1.5rem;
      border-radius: 3px;
      padding: 0.8rem;
      min-width: 12.5rem;
      text-align: center;
      background-color: $color-purple-2;
      color: $color-white;
      line-height: 3rem;
    }
  }

  input {
    max-width: 600px;
  }

  // .input-rename {
  //   width: 600px;
  // }

  .sonnant-logo a {
    color: $color-logo;
    margin-left: -2rem;
    font-size: 2.5rem;
    font-weight: 500;
  }
}
.input-rename:focus {
  outline: 1px blue;
}
.abortUpload {
  margin-top: 20px;
  float: right;
}
@media only screen and (min-width: 700px) and (max-width: 1300px) {
  // @media only screen and (max-width: 800px) {
  /*  .top-bar ul li a svg {
    width: 5rem;
  } */
  /*  .top-bar ul li.menu-text{
    margin-right: 0rem;
  } */

  /*  .top-bar ul li a {
    font-size: 17px;
  }   */
}

@media only screen and (max-width: 600px) {
  /*   .top-bar ul li a svg {
    width: 4rem;
  } */
  .top-bar ul li a {
    font-size: 15px;
    padding: 0.7rem 1rem;
  }
  .top-bar ul li.menu-text {
    margin-right: 0rem;
  }
}

@media only screen and (min-width: 200px) and (max-width: 1024px) {
  .top-bar .SignInSignUp {
    .upload {
      height: 4rem;
      width: 8.5rem;
      font-size: 1.4rem;
      margin-left: 3px;
      svg {
        width: 18px;
        height: 12px;
        margin-right: 4px;
      }
    }
    /* .searchSpan{
      width:3.5rem;
      height: 3.5rem;
      padding:0.5rem;
      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
    .bellSpan{
      width:3.5rem;
      height: 3.5rem;
      padding:0.5rem;
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    } */
  }
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .top-bar {
    height: max-content;
    padding: 15px;
    position: relative;

    .top-bar-left {
      ul {
        li {
          margin-right: 20px;
        }
      }
    }

    .SignInSignUp {
      // flex-flow: column-reverse;
      align-items: flex-end;
      margin: 0px;

      .search-container {
        margin-right: 4px;
        // margin-top: 15px;
        // width: 100%;

        .search-row {
          flex-grow: 1;

          .input-search {
            min-width: 100%;
          }
        }
      }

      .uploadTitle {
        display: none;
      }

      .upload {
        width: 40px;
        min-width: auto;
        padding: 0;

        svg {
          margin-right: 0px;
        }
      }
    }
  }
}

.disabled-link {
  cursor: not-allowed;
  opacity: 0.2;
  color: #000;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.3;
  background-color: #fff;
  pointer-events: none;
  &:hover {
    border: none !important;
  }
}

.bellSpan.disabled {
  background-color: #fff !important;
}
