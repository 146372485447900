$Purple-1   :#9B99FF;
$Purple-2   :#5551FF;
$Purple-3   :#211FCC;
$Purple-4   :#201F66;
$Green      :#19AFA7;
$Red        :#E7483D;
$Orange     :#FEB446;
$White      :#FFFFFF;
$Grey-1     :#F6F6F8;
$Grey-2     :#F0F0F4;
$Grey-3     :#E6E6EC;
$Grey-4     :#B2B3BC;
$Grey-5     :#7F8090;
$Grey-6     :#54566C;
$Grey-7     :#30324A;
$Black      :#000000;




