@import "../../sass/abstracts/variables";

.explore {
  background-color: $color-purple-4;
  width: 100%;
  height: calc(100vh - 7rem);
  position: absolute;
  top: 70px;
  span {
    margin: auto;
    width: 20rem;
    height: 18rem;
  }
}
