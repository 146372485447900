@import '../../../../../../../src/sass/abstracts/variables';

.layerNavigator {
  position: relative;

  .navigatorIcon {
    width: 2.2rem;
    height: 2.4rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 0.2rem;

    &:hover:not(:active) {
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);

      svg path {
        fill: $color-purple-2;
      }
    }

    &:active {
      svg path {
        fill: $color-white;
      }
    }
  }

  svg {
    width: 1.2rem !important;
    height: 1.2rem !important;
  }
}
