@import "../../../sass/abstracts/variables";
@import "../../../sass/base/input";

.tenantProfile {
  padding: 0px;
  align-items: center;
  .tenantTitle {
    h2 {
      color: #000000;
      font-weight: 700;
      font-size: 30px;
      letter-spacing: 0.05rem;
    }
  }
  .btnReset {
    float: right;
    button {
      margin:15px 0px 0px 15px;
      box-sizing: border-box;
      border-radius: 3px;
    }
    .reset {
      background: $color-white;
      border: 2px solid $color-grey-3;
      color: $color-grey-6;
    }
    .apply {
      background: $color-purple-2;
      color: $color-white;

      letter-spacing: 0.01rem;
    }
  }
}
.tenantProfile_picture {
  h3 {
    color: $color-grey-7;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.05rem;
  }
  .mgn_btm {
    margin-bottom: 20px;
  }
  .paramount {
    margin: 0px 10px 0px 0px;
  }
  // .paramount_title {
  //   position: relative;
  //   bottom: 14px;
  //   color: $color-grey-7;
  //   font-size: 16px;

  //   letter-spacing: 0.01rem;
  // }
  .paramount_title {
    font-weight: 300;
    display: inline;
    text-decoration: underline;
    cursor: pointer;
    color: $color-purple-2 !important;
    font-size: 1.8rem;
    vertical-align: middle;
    padding-left: 5px;
  }

  .noImage {
    display: none;
  }

  svg {
    vertical-align: middle;
  }

  .imageWrapper {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid transparent;
  }

  .userProfilePic {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
  }
  form {
    label {
      color: $color-grey-6;
      font-size: 14px;

      letter-spacing: 0.02rem;
      input {
        background: $color-grey-2;
        color: $color-grey-5;
        font-size: 16px;
      }
    }
  }
}

.switch_block {
  border: 1px solid $color-red;
  padding: 10px;
  margin-bottom: 20px;
  .switch {
    float: right;
    label {
      border-radius: 25px;
    }
    label:after {
      border-radius: 25px;
    }
    input:checked ~ .switch-paddle {
      background: $color-purple-2;
    }
  }
}

#updatePassword h1 {
  margin-bottom: 30px;
}
#updatePassword{
  .grid-x{
    flex-flow: row wrap;
  }
}

.uploadpw {
  margin-top: 30px;

  .cancel {
    width: 125px;
    height: 40px;
  }

  .updatepassword {
    width: 160px !important;
    height: 40px;
    background: $color-purple-2 !important;

  }
}

 
.btn-Disabled {
  opacity: 0.4;
  pointer-events: none;
}

.passwordUpdate {
  height: 40px;
  font-size: 15px;
  margin-left: 10px;
}
