@mixin topNotification {
  background-color: $color-orange;
  color: $color-white;
  font-size: 1.8rem;
  line-height: 5rem;
  text-align: center;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 12px;
  line-height: 24px !important;
  &.error {
    background-color: $color-red;
  }
  &.success {
    background-color: $color-green;
  }
}
@mixin closeButton {
  position: relative;
  left: 15px !important;
  display: inline;
  cursor: pointer;
}

@mixin btn-medium {
  font-size: 1.4rem;
  border-radius: 3px;
  padding: 0.5rem 2rem;
  height: 30px;
}

@mixin btn-large {
  font-size: 1.5rem;
  border-radius: 4px;
  padding: 0.5rem;
  min-width: 12.5rem;
  line-height: 3rem;
  width: 125px;
  height: 40px;

  //   &:focus {
  //     background: $color-purple-1;
  //     box-shadow: 0px 0px 15px 10px $color-purple-1;
  //   }
}

// @mixin btn-large:focus {
//   background: #9b99ff;
// }

$breakpoints: (
  xlarge: 1535px,
);

$breakpoint-classes: (small medium large xlarge);

@mixin breakpoint($size) {
  @media (min-width: map-get($breakpoints, $size )) {
    @content;
  }
}
