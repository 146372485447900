@import '../../../sass/abstracts/variables';
@import '../../../sass/base/input';

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.grid-container.fluid {
  padding-left: 15px;
  padding-right: 15px;
}
.btnDisabled {
  pointer-events: none;
}
.preferences {
  padding: 0px;
  align-items: center;
  position: static;

  .prefTitle {
    margin: 2rem 0px 1rem;
    h2 {
      color: #000000;
      font-weight: 700;
      font-size: 30px;
      letter-spacing: 0.05rem;
    }

    h3 {
      color: #030303;
    }
  }
  .btnReset {
    float: right;
    button {
      margin: 15px 0px 0px 15px;
      box-sizing: border-box;
      border-radius: 3px;
      font-size: 15px;
      height: 40px;
      width: 125px;
    }
    .reset {
      background: $color-white;
      border: 2px solid $color-grey-3;
      color: $color-grey-6;
      &:active {
        color: $color-white !important;
      }
    }
    .btn-Disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    .apply {
      background: $color-purple-2;
      color: $color-white;
      letter-spacing: 0.01rem;

      &:focus {
        background: $color-purple-1;
        border: 2px solid $color-grey-2;
        box-shadow: 0px 0px 15px 10px #9b99ff;
        color: $color-white;
      }
      &:active {
        background: #19afa7;
        box-shadow: none;
        border: none;
        color: $color-white !important;
      }
    }
  }
}
.preferences-body {
  p.title {
    padding-left: 70px;
    color: $color-purple-2;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.05rem;
  }
  .mgn_btm {
    margin-bottom: 20px;
    ul.pref_list {
      list-style-type: none;
    }
  }
  .options {
    color: $color-purple-3;
    font-size: 20px;
  }
  .Publish {
    padding-top: 10rem;
    .PublishModal {
      padding-left: 15rem;
    }
  }
}

// Hide default password reveal button in browsers
input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}
