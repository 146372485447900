body {
    font-family: 'Roboto', 'Arial', 'Helvetica', 'Verdana';
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: $color-purple-4;
}

.heading-primary {
    text-align: center;
    color:#000000;
    font-weight: 700;
    font-size: 4.0rem;
    line-height: 51.56rem;
    line-height: 5.8rem;
}

.heading-secondary {
    font-size: 3.4rem;
    font-weight: 700;
    line-height: 39.84rem;
    text-align: center;
}

.heading-tertiary {
    font-size: $default-font-size;
    font-weight: 700;
    text-transform: uppercase;
}
