// @import "~foundation-sites/scss/util/mixins";
@import "~foundation-sites/scss/util/mixins";
@import "../abstracts/mixins";

// #root form:not(.signupForm)~
//     div .grecaptcha-badge{
//         visibility: hidden;
//     }

.input:checked {
  color: red;
}
button,
.btn-primary {
  @include button-style($color-purple-2, $color-purple-2, $color-white);
  border-radius: 3px;
  &.large {
    @include btn-large;
  }
  &.medium {
    @include btn-medium;
  }
  &:disabled {
    background-color: $color-grey-5 !important;
    opacity: 0.4 !important;
  }
  &:hover:not(:disabled) {
    // border: 2px solid $color-white;
    /* Hovered - Drop Shadow */

    // box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    opacity: .93;
  }
  &:focus {
    background: $color-purple-1;
    /* Colour/Grey-2 */
    // border: 2px solid $color-grey-2;
    /* Focused - Drop Shadow */
    box-shadow: 0px 0px 15px 5px $color-purple-1;
    // border-radius: 5px;
  }
  &:active {
    /* Colour/Green */

    background: $color-green !important;
    /* Colour/Green */
    color: $color-white !important;
    border: none;

    border: 2px solid $color-purple-2;
    color: $color-purple-2 !important;
  }
}
.btn-secondary {
  /* Colour/White */
  background: $color-white;
  /* Colour/Grey-3 */
  border: 2px solid $color-grey-3;
  border-radius: 3px;
  text-align: center;
  color: $color-grey-6;
  &.large {
    @include btn-large;
    line-height: unset !important;
  }
  &.tiny {
    width: 40px;
    height: 40px;
  }
  &:disabled {
    background: $color-white;
    color: $color-grey-6;
    opacity: 0.4;
    border: 2px solid transparent !important;
  }
  &:hover {
    background: $color-white;
    color: $color-grey-6;
    border: 2px solid $color-purple-2 !important;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  }
  &:focus {
    background: $color-white;
    color: $color-grey-6;
    /* Colour/Grey-2 */

    background: $color-grey-2 !important;
    /* Colour/Purple-1 */

    border: 2px solid $color-purple-1;
    // box-sizing: border-box;
    /* Focused - Drop Shadow */

    box-shadow: 0px 0px 1.5rem 1rem $color-purple-1;
    // border-radius: 3px;
  }
  &:active {
    background: $color-white;
    color: $color-grey-6;
    background: $color-green;
    /* Colour/Green */

    border: 2px solid $color-green;
    // box-sizing: border-box;
    // border-radius: 3px;
  }
}
label {
  text-align: left;
  font-size: 1.4rem;
  color: $color-grey-6;
  font-weight: 500;
  position: relative;
}

input:not([type="checkbox"]) {
  height: 40px;
  font-size: 1.6rem;
  background: $color-grey-2;
  border-radius: 3px;
  &.error {
    border: 2px solid $color-red;
  }
  &.warning {
    border-color: $color-orange;
  }
}

.loader__component {
  opacity: 0.8;
  text-align: center;
  z-index: 100000;
  background-color: white !important;
  overflow-y: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Global Margins - margin-# or m# increments of five until 50px then jumps to 50px */
.m0,.margin-0 { margin: 0px; }
.m5,.margin-5 { margin: 5px; }
.m10,.margin-10 { margin: 10px; }
.m15,.margin-15 { margin: 15px; }
.m20,.margin-20 { margin: 20px; }
.m25,.margin-25 { margin: 25px; }
.m30,.margin-30 { margin: 30px; }
.m35,.margin-35 { margin: 35px; }
.m40,.margin-40 { margin: 40px; }
.m45,.margin-45 { margin: 45px; }
.m50,.margin-50 { margin: 50px; }
.m100,.margin-100 { margin: 100px; }
.m150,.margin-150 { margin: 150px; }
.m200,.margin-200 { margin: 200px; }
.m250,.margin-250 { margin: 250px; }

/* Global Padding */
.p0,.padding-0 { padding: 0px; }
.p5,.padding-5 { padding: 5px; }
.p10,.padding-10 { padding: 10px; }
.p15,.padding-15 { padding: 15px; }
.p20,.padding-20 { padding: 20px; }
.p25,.padding-25 { padding: 25px; }
.p30,.padding-30 { padding: 30px; }
.p35,.padding-35 { padding: 35px; }
.p40,.padding-40 { padding: 40px; }
.p45,.padding-45 { padding: 45px; }
.p50,.padding-50 { padding: 50px; }
.p100,.padding-100 { padding: 100px; }
.p150,.padding-150 { padding: 150px; }
.p200,.padding-200 { padding: 200px; }
.p250,.padding-250 { padding: 250px; }

/* Specific Margins */

/* Margin Top - mt# or margin-top-# */
/* These should override the global margins so you can use m50 mt5 or margin-50 margin-top-5, yeah i got bored and just went up in 10s */
.mt0,.margin-top-0 { margin-top:0px; }
.mt5,.margin-top-5 { margin-top:5px; }
.mt10,.margin-top-10 { margin-top:10px; }
.mt20,.margin-top-20 { margin-top:20px; }
.mt30,.margin-top-30 { margin-top:30px; }
.mt40,.margin-top-40 { margin-top:40px; }
.mt50,.margin-top-50 { margin-top:50px; }
.mt100,.margin-top-100 { margin-top:100px; }
.mt150,.margin-top-150 { margin-top:150px; }
.mt200,.margin-top-200 { margin-top:200px; }
.mt250,.margin-top-250 { margin-top:250px; }

/* Margin Bottom - mb# or margin-bottom-# */
.mb0,.margin-bottom-0 { margin-bottom:0px; }
.mb5,.margin-bottom-5 { margin-bottom:5px; }
.mb10,.margin-bottom-10 { margin-bottom:10px; }
.mb20,.margin-bottom-20 { margin-bottom:20px; }
.mb30,.margin-bottom-30 { margin-bottom:30px; }
.mb40,.margin-bottom-40 { margin-bottom:40px; }
.mb50,.margin-bottom-50 { margin-bottom:50px; }
.mb100,.margin-bottom-100 { margin-bottom:100px; }
.mb150,.margin-bottom-150 { margin-bottom:150px; }
.mb200,.margin-bottom-200 { margin-bottom:200px; }
.mb250,.margin-bottom-250 { margin-bottom:250px; }

/* Margin Left - ml# or margin-left-# */
.ml0,.margin-left-0 { margin-left:0px; }
.ml5,.margin-left-5 { margin-left:5px; }
.ml10,.margin-left-10 { margin-left:10px; }
.ml20,.margin-left-20 { margin-left:20px; }
.ml30,.margin-left-30 { margin-left:30px; }
.ml40,.margin-left-40 { margin-left:40px; }
.ml50,.margin-left-50 { margin-left:50px; }
.ml100,.margin-left-100 { margin-left:100px; }
.ml150,.margin-left-150 { margin-left:150px; }
.ml200,.margin-left-200 { margin-left:200px; }
.ml250,.margin-left-250 { margin-left:250px; }

/* Margin Right - mr# or margin-right-# */
.mr0,.margin-right-0 { margin-right:0px; }
.mr5,.margin-right-5 { margin-right:5px; }
.mr10,.margin-right-10 { margin-right:10px; }
.mr20,.margin-right-20 { margin-right:20px; }
.mr30,.margin-right-30 { margin-right:30px; }
.mr40,.margin-right-40 { margin-right:40px; }
.mr50,.margin-right-50 { margin-right:50px; }
.mr100,.margin-right-100 { margin-right:100px; }
.mr150,.margin-right-150 { margin-right:150px; }
.mr200,.margin-right-200 { margin-right:200px; }
.mr250,.margin-right-250 { margin-right:250px; }

/* Specific Padding */
/* Top Padding - pt# or padding-top-# */
.pt0,.padding-top-0 { padding-top:0px; }
.pt5,.padding-top-5 { padding-top:5px; }
.pt10,.padding-top-10 { padding-top:10px; }
.pt20,.padding-top-20 { padding-top:20px; }
.pt30,.padding-top-30 { padding-top:30px; }
.pt40,.padding-top-40 { padding-top:40px; }
.pt50,.padding-top-50 { padding-top:50px; }
.pt100,.padding-top-100 { padding-top:100px; }
.pt150,.padding-top-150 { padding-top:150px; }
.pt200,.padding-top-200 { padding-top:200px; }
.pt250,.padding-top-250 { padding-top:250px; }

.pb0,.padding-bottom-0 { padding-bottom:0px; }
.pb5,.padding-bottom-5 { padding-bottom:5px; }
.pb10,.padding-bottom-10 { padding-bottom:10px; }
.pb20,.padding-bottom-20 { padding-bottom:20px; }
.pb30,.padding-bottom-30 { padding-bottom:30px; }
.pb40,.padding-bottom-40 { padding-bottom:40px; }
.pb50,.padding-bottom-50 { padding-bottom:50px; }
.pb100,.padding-bottom-100 { padding-bottom:100px; }
.pb150,.padding-bottom-150 { padding-bottom:150px; }
.pb200,.padding-bottom-200 { padding-bottom:200px; }
.pb250,.padding-bottom-250 { padding-bottom:250px; }

.pr0,.padding-right-0 { padding-right:0px; }
.pr5,.padding-right-5 { padding-right:5px; }
.pr10,.padding-right-10 { padding-right:10px; }
.pr20,.padding-right-20 { padding-right:20px; }
.pr30,.padding-right-30 { padding-right:30px; }
.pr40,.padding-right-40 { padding-right:40px; }
.pr50,.padding-right-50 { padding-right:50px; }
.pr100,.padding-right-100 { padding-right:100px; }
.pr150,.padding-right-150 { padding-right:150px; }
.pr200,.padding-right-200 { padding-right:200px; }
.pr250,.padding-right-250 { padding-right:250px; }

.pl0,.padding-left-0 { padding-left:0px; }
.pl5,.padding-left-5 { padding-left:5px; }
.pl10,.padding-left-10 { padding-left:10px; }
.pl20,.padding-left-20 { padding-left:20px; }
.pl30,.padding-left-30 { padding-left:30px; }
.pl40,.padding-left-40 { padding-left:40px; }
.pl50,.padding-left-50 { padding-left:50px; }
.pl100,.padding-left-100 { padding-left:100px; }
.pl150,.padding-left-150 { padding-left:150px; }
.pl200,.padding-left-200 { padding-left:200px; }
.pl250,.padding-left-250 { padding-left:250px; }

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.svg-shadow {
  -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));
}

.svg-shadow-hover:hover {
  -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.6));
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.6));
}

.questionsvg {
  svg {
    path {
      fill: $color-grey-5;
    }
    &:hover path {
      fill: $color-purple-2;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.share-modal-width {
  min-width: 600px;
  width: 940px !important;
  left: calc(50% - 470px) !important;
}

.publish-modal-width {
  width: 700px !important;
  left: calc(50% - 350px) !important;
}

.applied-lists-modal {
  width: 900px !important;
  left: calc(50% - 450px) !important;
}

.disable-enable-user-modal-width {
  width: 860px !important;
  left: calc(50% - 430px) !important;
}

@media only screen and (max-width: 1023px)  {
  .applied-lists-modal {
    width: 80rem !important;
    left: calc(50% - 400px) !important;
  }
}

.iab-modal {
  width: 1000px !important;
  left: calc(50% - 500px) !important;
}

.userModal_Popus {
  border-radius: 3px;
  border: none;
  &:focus {
    outline: none;
  }
  h1 {
    font-weight: 700;
    font-size: 32px;
    color: $color-black;
    margin: 0px;
  }
  .close-button {
    margin-top: 18px;
    margin-right: 10px;
    background: none;
    border: none;
    color: $color-black;

    &:hover {
      background: none;
      box-shadow: none;
    }
    &:focus {
      background: none;
      box-shadow: none;
    }
  }
  .deleteInfo {
    text-align: center;
    background: #feb446;
    padding: 15px;
    margin: 25px 0 40px 0px;
    p {
      font-size: 16px;
      margin-bottom: 0;
      color: $color-white;
    }
  }
  .btn_section {
    float: right;
    .button {
      font-size: 15px;
      border-radius: 3px;
      width: 130px;
      margin: 0px 10px 0px 0px;
    }
    .button.cancel {
      border: 2px solid $color-grey-3;
      background: none;
      color: $color-black;
    }
    .button.delete {
      margin-right: 0px;
      background: $color-purple-2;
      color: $color-white;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
