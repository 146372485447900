@import "../../../sass/base/input";

.Modal {
  padding: 2rem 3rem;
}
.rightAlign {
  float: right;
  margin-top: 15px;
}
.addUserPopup {
  h2 {
    font-weight: 700;
    font-size: 30px;
  }

  .userInfo {
    padding: 20px 0px;
  }
}

.topMessage.success {
  @apply bg-sonnant-green;
}

.topMessage span.closeButton {
  float: right;
  margin-right: 2rem;
}

.userrole:focus {
  background-color: #fff !important;
}
