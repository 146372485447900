@import "../../../styles/colors";
@import "../../../sass/abstracts/mixins";
@import "../../../sass/abstracts/variables";

#video-container {
  width: 100%;
  height: 365px;
  position: relative;
}

.top-bar {
  position: relative;
}

.nav_bg_shadow h1 {
  font-size: 30px;
  font-weight: 700;
}

#video-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  transition: opacity 0.3s;
  background-image: linear-gradient(
    to top,
    rgb(3, 113, 168) 13%,
    rgb(0, 136, 204) 100%
  );
  background-image: -o-linear-gradient(
    to top,
    rgb(3, 113, 168) 13%,
    rgb(0, 136, 204) 100%
  );
  background-image: -moz-linear-gradient(
    to top,
    rgb(3, 113, 168) 13%,
    rgb(0, 136, 204) 100%
  );
  background-image: -webkit-linear-gradient(
    to top,
    rgb(3, 113, 168) 13%,
    rgb(0, 136, 204) 100%
  );
  background-image: -ms-linear-gradient(
    to top,
    rgb(3, 113, 168) 13%,
    rgb(0, 136, 204) 100%
  );

  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.13, rgb(3, 113, 168)),
    color-stop(1, rgb(0, 136, 204))
  );
}

#video-container:hover #video-controls {
  opacity: 0.9;
}

button {
  border: 0;
  color: #eee;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}

button:hover {
  cursor: pointer;
}

#seek-bar {
  width: 360px;
}

#volume-bar {
  width: 60px;
}

#public-transcript.video_mgn {
  margin-top: 10px;
  padding: 0px 5px;
  padding-bottom: 0px !important;
  .editInpt {
    padding: 5px 15px 12px;
  }
  .left_blcok {
    display: flex;
    .arrow_block {
      display: flex;
      .button {
        background: $White;
        border: 2px solid $Grey-3;
        border-radius: 3px;
        padding: 10px 6px;
        height: 40px;
        width: 40px;
        margin-bottom: 0;
      }
      .button:hover {
        background-color: $White;
        border: 2px solid $Purple-2;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
        svg {
          path {
            fill: $Purple-2;
          }
        }
      }
      .button:focus {
        box-shadow: 0px 0px 15px 10px #9b99ff;
        background: #f6f6f8;
        border: none;
        outline: none;
        svg {
          path {
            fill: $Purple-1;
          }
        }
      }
      .button:active {
        background: #19afa7;
        box-shadow: none;
        border: none;
        outline: none;
        svg {
          path {
            fill: $White;
          }
        }
      }
    }
    .btn_block {
      display: flex;
      .button {
        height: 40px;
        width: 40px;
        border-radius: 3px;
      }
      .button:hover {
        background-color: $White;
        border: 2px solid $Purple-2;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
        // svg {
        //   #play_x5F_alt {
        //     path {
        //       fill: $Purple-2 !important;
        //     }
        //   }
        //   path {
        //     fill: $Purple-2;
        //   }
        // }
      }
      .button:focus {
        box-shadow: 0px 0px 15px 10px #9b99ff;
        background: #f6f6f8;
        border: none;
        outline: none;
        // svg {
        //   path {
        //     fill: $Purple-1 !important;
        //   }
        // }
      }
      .button:active {
        background: #19afa7;
        box-shadow: none;
        border: none;
        outline: none;
        svg {
          path {
            fill: $White !important;
          }
        }
      }
    }
    .content_block {
      span {
        color: $Grey-6;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        vertical-align: -webkit-baseline-middle;
        letter-spacing: 0.2rem;
      }
      h6 {
        color: $Black;
        margin: 5px 0px 5px;
        font-size: 15px;
        font-weight: 700;
      }
    }
    .btn_block {
      .button {
        background: $White;
        border: 2px solid $Grey-3;
      }
    }
  }
}
.bg_shadow {
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  background: $White;
  border-right: 1px solid $Grey-3;
  &:hover {
    background: rgba(240, 240, 255, 0.5);
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  }
  .flex_width {
    width: max-content;
  }
}

.keywordsBlock {
  padding-top: 25px;
}

.plusIcon {
  padding: 5px;
  svg {
    font-size: 10px;
    path {
      fill: #ffffff;
    }
  }
}

.progress_section {
  display: flex;
  justify-content: space-between;
}

.controlBlock {
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  height: 80px;
  padding: 15px 15px;
  background: #050a38;
  color: $White;
  .rePlay {
    // position: relative;
    // top: 10px;
    margin: 0px 5px;
    cursor: pointer;
  }
  .timer {
    position: relative;
    top: 15px;
    width: 100px;
    font-size: 12px;
  }
  .durationTimer {
    font-size: 12px;
    position: relative;
    top: 13px;
    left: 12px;
  }
  .progress {
    position: relative;
    top: 21px;
    margin-right: 7px;
    padding: 0px 0px;
    height: 10px;
    border-radius: 25px;
    background: #54566c;
    cursor: pointer;
    width: 100%;

    .progress-meter {
      border-radius: 25px;
      background: $Purple-2;
      height: 10px;
    }
  }
  .volumeBtn {
    position: absolute;
    right: 0;
    top: 22px;
  }
  .plaBackBtn {
    background: transparent;
    border: none;
    position: relative;
    top: 2px;
    width: 70px;
    font-size: 18px;
    &:hover {
      border: none;
    }
  }
  .btnLineHeigh {
    line-height: 2.2;
    // width: 220px;
    span {
      position: absolute;
      font-size: 7px;
      margin: 14px 9px;
    }
    span.leftAlgin {
      margin: 14px 8px;
    }
  }
  .play-pause-btn {
    float: left;
    cursor: pointer;
    background: $Purple-2;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    .playSvg,
    .pauseSvg {
      fill: #fff;
      width: 40px;
      height: 40px;
      position: relative;
      top: 5px;
      left: 5px;
    }
  }
}

.transcript-auto-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

.transcript-auto-scroll::-webkit-scrollbar {
  width: 10px;
  height: 120px;
  background-color: $Grey-3;
}

.transcript-auto-scroll::-webkit-scrollbar-track-piece {
  background-color: #f5f5f5;
}

.transcript-auto-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $Grey-3;
}

.external_ctrols {
  background-color: $Grey-2;
  // height: 50px;
  // margin: -7px 0px;
  .button {
    background: none;
    width: 50px;
    height: 50px;
    margin: 0px;
    padding: 0;
  }
  button.button:hover {
    border-radius: 3px;
    border: 2px solid $Purple-2;
    svg {
      path {
        fill: $Purple-2;
      }
    }
  }
  button.button:focus {
    border: none;
    box-shadow: 0px 0px 15px 10px #9b99ff;
    background: $Grey-2 !important;
    border-radius: 3px;
    svg {
      path {
        fill: $Purple-1;
      }
    }
  }
  button.button:active {
    border: none;
    background: #19afa7 !important;
    border-radius: 3px;
    svg {
      path {
        fill: $White;
      }
    }
  }
  .pull-right {
    float: right;
  }
}

.public_media {
  // .progress:hover .progress-meter:after {
  //   opacity: 1;
  // }

  .transcript-auto-scroll {
    // height: $player-available-height !important;
    overflow: scroll;
  }

  .video-container {
    // height: $player-available-height !important;
  }

  .progress {
    cursor: pointer;
  }

  .progress-meter {
    position: absolute;
    width: 0;
    height: 3px;
    border-radius: 3px 0 0 3px;
  }

  .progress-meter {
    background: $Purple-2;
    z-index: 1;
  }
  .progress-meter:after {
    position: absolute;
    top: 0;
    right: -10px;
    width: 15px;
    height: 15px;
    margin-top: -2px;
    content: "";
    border-radius: 15px;
    background: $White;
    opacity: 1;
  }

  .progress-meter:after {
    transform: scale(1.4);
  }

  .vol_ctrl_block {
    display: inline-block;
    position: relative;
    cursor: pointer;
    text-align: center;
    &:hover .volume-control {
      visibility: visible;
      opacity: 1;
      z-index: 1;
    }

    .button.valBtn {
      background: transparent;
      border: none;

      &:hover {
        background: transparent !important;
        border: none !important;
      }
      &:active {
        background: transparent !important;
        border: none !important;
      }
      &:focus {
        background: transparent !important;
        border: none !important;
      }
    }
  }

  .volume-control {
    position: absolute;
    visibility: hidden;
    border: 1px solid #aaa;
    background: #dedede;
    width: 60px;
    left: -8px;
    height: 300px;
    bottom: 35px;
    box-shadow: 2.5px 2.5px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    .pluIcon,
    .minusIcon {
      position: relative !important;
      svg {
        height: 16px;
        path {
          fill: $Grey-7;
        }
      }
    }
    .pluIcon {
      left: 0px;
      top: 10px;
    }
    .minusIcon {
      top: -2px;
      left: 0px;
      svg {
        width: 12px;
      }
    }
    .range-slider {
      display: inline-block;
      width: 40px;
      position: relative;
      text-align: center;
      height: 205px;
      top: 8px;
      //max-height: 100%;
      &:before {
        position: absolute;
        top: -2em;
        left: 0.5em;
        content: "";
        color: white;
        font-size: 90%;
      }
      input[type="range"][orient="vertical"] {
        position: relative;
        margin: 0;
        height: 100%;
        width: 100%;
        display: inline-block;
        position: relative;
        writing-mode: bt-lr;
        cursor: pointer;
        -webkit-appearance: slider-vertical;
        &::-webkit-slider-runnable-track {
          -webkit-appearance: none;
          border: none;
          width: 6px;
          background: $Purple-2;
          box-shadow: inset 1px 1px 2px 2px rgba(0, 0, 0, 0.25);
          border-radius: 25px;
        }
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 25px;
          height: 25px;
          opacity: 0;
        }
        &::-moz-range-track {
          border: none;
          width: 8px;
          background: $Purple-2;
          box-shadow: inset 1px 1px 2px 2px rgba(0, 0, 0, 0.25);
          border-radius: 25px;
        }
        &::-ms-track {
          border: none;
          width: 8px;
          background: $Purple-2;
          box-shadow: inset 1px 1px 2px 2px rgba(0, 0, 0, 0.25);
          border-radius: 25px;
          color: transparent;
          height: 100%;
        }
        &::-ms-fill-lower {
          display: none;
        }
        &::-ms-fill-upper {
          display: none;
        }
        &::-ms-tooltip {
          display: none;
        }
        &::-moz-range-thumb {
          width: 25px;
          height: 25px;
          opacity: 0;
        }
        &::-ms-thumb {
          width: 25px;
          height: 25px;
          opacity: 0;
        }
      }
    }
    .range-slider__thumb {
      position: absolute;
      left: 7px;
      width: 25px;
      height: 25px;
      line-height: 30px;
      color: #777;
      border: 3px solid #ffffff;
      background: $Purple-2;
      font-size: 50%;
      box-shadow: 2.5px 2.5px 5px rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      pointer-events: none;
    }
    .range-slider__bar {
      left: 16px;
      bottom: 0;
      height: 130px;
      position: absolute;
      background: $Purple-2;
      box-shadow: inset 1px 1px 2px 2px rgba(0, 0, 0, 0.25);
      pointer-events: none;
      width: 8px;
      border-radius: 25px;
    }
  }

  .ct_Width {
    width: 5% !important;
  }

  .mediaProgressLoader {
    text-align: center;
  }

  .sub-menu-parent {
    position: relative;
  }

  .sub-menu {
    // visibility: hidden;
    display: none;
    opacity: 0;
    position: absolute;
    bottom: 100%;
    height: 260px;
    left: 0;
    width: 100%;
    transform: translateY(-2em);
    z-index: -1;
    margin-left: 0px;
    margin-bottom: -5px;
    box-shadow: 3px -2px 5px -2px $Grey-6;
    border: 1px solid $Grey-4;
    border-radius: 3px;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
      z-index 0s linear 0.01s;
    li {
      list-style: none;
      padding: 7px 12px;
      color: $Grey-6;
    }
  }

  .sub-menu-parent:focus .sub-menu,
  .sub-menu-parent:focus-within .sub-menu,
  .sub-menu-parent:hover .sub-menu {
    // visibility: visible;
    display: block;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
  }

  .sub-menu {
    background: $White;
  }

  .audio_mute {
    fill: #fff;
  }

  .transcript-auto-scroll {
    width: 100%;
    border-right: 1px solid $Grey-3;
  }

  @media only screen and (min-width: 1150px) and (max-width: 1300px) {
    .transcript-auto-scroll {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  @media only screen and (max-width: 1400px) {
    .transcript-auto-scroll {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  @media only screen and (min-width: 1400px) and (max-width: 1600px) {
    .transcript-auto-scroll {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  @media only screen and (min-width: 1600px) {
    .transcript-auto-scroll {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  @media (min-width: 2052px) and (max-width: 2732px) {
    .transcript-auto-scroll {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  @media (min-width: 2733px) and (max-width: 4102px) {
    .transcript-auto-scroll {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .ct_Width {
      width: 7% !important;
    }

    .video_mgn {
      .left_blcok {
        .arrow_block {
          width: 80px;
          margin: 10px 5px 10px 0px;
        }
        .btn_block {
          display: contents;
          .button {
            margin: 25px 0px 0px 10px;
            position: relative;
            top: -15px;
          }
        }
        .content_block {
          margin: 0px 5px 0px 5px;
        }
      }
    }
  }

  .keyterms {
    // width:450px !important;
    background: #7f8090;
    text-align: center;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 40px;
    // margin-top: 30px;

    span {
      // padding-left: 5px;
      color: #fff;
    }
  }

  @media screen and (min-width: 1350px) and (max-width: 1439px) {
    .video-container {
      width: 100%;
    }
    .keyterms {
      width: 100% !important;
    }
  }

  @media screen and (min-width: 1260px) and (max-width: 1349px) {
    .keyterms {
      width: 420px !important;
    }
  }

  @media screen and (min-width: 1180px) and (max-width: 1259px) {
    .video-container {
      width: 98%;
    }

    .keyterms {
      width: 400px !important;
    }
  }

  .keywords {
    margin-top: 8px;
    margin-bottom: 15px;
  }

  .keywordBtn {
    display: flex;
    position: relative;
    justify-content: center;
    min-width: 125px;
    max-width: 260px;
    margin-right: 10px;
    line-height: 2.5rem;
    font-size: 15px;
    height: 40px;
    font-size: 1.5rem;
    border-radius: 3px;
    padding: 5px 10px;
    margin-bottom: 12px;
    .keywordval {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 220px;
      font-weight: 500;
    }
    &:hover:not(.selectedKeyword) {
      .badge {
        background: $Purple-2;
      }
    }
    // &:focus{
    //   border: 2px solid $Purple-1 !important;
    //   box-shadow: 0px 0px 15px 10px #9B99FF !important;
    //   background: $White !important;
    //   box-sizing: border-box;
    //   .badge{
    //     background: #9b99ff;
    //   }
    // }
    &:active {
      background: #19afa7 !important;
      color: $White;
      .badge {
        background: $White;
        color: #19afa7;
      }
    }
  }

  svg.playBackPause {
    background: #7f8090;
    border-radius: 50%;
    padding: 1px;
    &:hover {
      svg {
        background: $Purple-2;
        g {
          path {
            fill: $White !important;
          }
        }
      }
    }
  }
}

// Small devices (phones, 640px and up)
@media (max-width: 640px) and (orientation: portrait) {
  .share-container {
    // height: calc(100vh - 72px);
    // height: calc((var(--vh, 1vh) * 100) - 72px);
    // overflow: hidden;

    > div:last-of-type {
      // overflow: hidden;
    }

    > div {
      .video-container {
        width: 100% !important;
        height: max-content !important;
      }
      .btn-control {
        justify-content: space-between;
      }
      .controlBlock {
        position: relative !important;
        padding: 15px 15px;
        background: #201f66;
        color: #ffffff;
        height: auto !important;
        .timer {
          top: 0px !important;
        }
        .durationTimer {
          top: 0px !important;
        }
        .progress {
          top: 6px !important;
        }

        .bnt-share{
          svg{
            path{
              fill: #ffffff;
            }
          }
        }
      }

      > .btn-group {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        .button {
          margin-bottom: 0px;
          border: 1px solid rgb(116, 116, 116);
          border-radius: 3px;
          height: 35px;
          min-width: 35px;
          background: white;
        }
        .dropdown-tabs {
          background-color: #5551ff;
          color: #f0f0f4;
          padding: 6px 10px;
          flex-grow: 0.8;
          // width: 40%;
          text-align: center;
          border-radius: 3px;
          font-size: 13px;
          height: 35px;
        }

        .btn-tab-control{
          .arrow-down{
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #ffffff;
            top: 6px;
            position: relative;
            vertical-align: text-top;
            margin-left: 10px;
          }
        }
        
        .btn-tab-control:focus{
          border: 0px !important;
          width: 100%;          
        }

        .btn-tab-control.sub-menu-parent:focus .sub-menu,
        .btn-tab-control.sub-menu-parent:focus-within .sub-menu,
        .btn-tab-control.sub-menu-parent:hover .sub-menu {
          display: block;
          opacity: 1;
          z-index: 1;
          top: 35px;
          width: 100%;
          height: fit-content;
          text-align: left;

          li{
            font-size: 14px;
            padding: 8px 12px;
          }
        }

        .btn-group {
          padding: 0px;
        }
        .btn-group > .button:first-of-type {
          border-radius: 3px 0px 0px 3px;
        }
        .btn-group > .button:last-of-type {
          border-radius: 0px 3px 3px 0px;
        }
      }
      .video_mgn {
        margin-top: 0px;
        flex-grow: 1;
        overflow: hidden;
        .transcript-auto-scroll {
          height: 100%;
          .bg_shadow {
            min-height: 60px;
            padding: 15px 0px;
            flex-flow: column;
            .editInpt {
              font-size: 13px;
              line-height: 22px;
              margin-top: 0px;
              padding-left: 15px;
            }

            .reduced_sequence_area {
              width: 100%;

              .left_blcok .content_block {
                display: flex;
                span:first-of-type::after {
                  content: "-";
                  padding: 4px;
                }

                span {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      .video_mgn.hidden{
        visibility: hidden;
      }
    }

    .keyterms{
      display: none !important;
    }

    .keywords {
      height: fit-content;
      margin: 6px 6px;
      width: 100%;
      overflow-x: scroll;

      .left_blcok{
        display: none;
      }

      .key-scroll-wrapper{
        height: 100%;
        flex-wrap: nowrap;
        width: max-content;
        margin-top: 0px;

        .keywordBtn {
          margin-bottom: 6px;
          font-size: 12px;
        }
      }

      .key-scroll-wrapper::-webkit-scrollbar{
        height: 0 !important;
      }
    }::-webkit-scrollbar{
      height: 0px !important;
    }
  }
}

// Small devices Landscape(phones, 640px and up)
@media (max-width: 926px) and (orientation: landscape) {
  .share-container {
    height: calc(100vh - 72px);
    height: calc((var(--vh, 1vh) * 100) - 72px);
    overflow: hidden;
    flex-direction: row-reverse;

    > div {
      width: 50%;

      .video-container {
        width: auto;
        height: calc(100vh - 130px) !important;
        height: calc((var(--vh, 1vh) * 100) - 130px) !important;
      }
      .btn-control {
        justify-content: space-between;
        margin-top: px;
      }
      .controlBlock {
        position: relative !important;
        padding: 8px 15px;
        background: #201f66;
        color: #ffffff;
        height: auto !important;
        .timer {
          top: 0px !important;
        }
        .durationTimer {
          top: 0px !important;
        }
        .progress {
          top: 6px !important;
        }
      }

      > .btn-group {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        .button {
          margin-bottom: 0px;
          border: 1px solid rgb(116, 116, 116);
          border-radius: 3px;
          height: 35px;
          min-width: 35px;
          background: white;
        }
        .dropdown-tabs {
          flex-grow: 0.6;
          background-color: #5551ff;
          color: #f0f0f4;
          padding: 6px 12px;
          // width: 50%;
          text-align: center;
          border-radius: 3px;
          font-size: 11px;
          height: 35px;
        }

        .btn-tab-control{
          .arrow-down{
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #ffffff;
            top: 6px;
            position: relative;
            vertical-align: text-top;
            margin-left: 10px;
          }
        }
        
        .btn-tab-control:focus{
          border: 0px !important;
          width: 100%;          
        }

        .btn-tab-control.sub-menu-parent:focus .sub-menu,
        .btn-tab-control.sub-menu-parent:focus-within .sub-menu,
        .btn-tab-control.sub-menu-parent:hover .sub-menu {
          display: block;
          opacity: 1;
          z-index: 1;
          top: 35px;
          width: 100%;
          height: fit-content;
          text-align: left;

          li{
            font-size: 14px;
            padding: 8px 12px;
          }
        }

        .btn-group {
          padding: 0px;
        }
        .btn-group > .button:first-of-type {
          border-radius: 3px 0px 0px 3px;
        }
        .btn-group > .button:last-of-type {
          border-radius: 0px 3px 3px 0px;
        }
      }
      .video_mgn {
        margin-top: 0px;
        flex-grow: 1;
        overflow: hidden;
        .transcript-auto-scroll {
          height: 100%;
          .bg_shadow {
            min-height: 60px;
            padding: 15px 0px;
            flex-flow: column;
            .editInpt {
              font-size: 13px;
              line-height: 22px;
              margin-top: 0px;
              padding-left: 15px;
            }

            .reduced_sequence_area {
              width: 100%;

              .left_blcok .content_block {
                display: flex;
                span:first-of-type::after {
                  content: "-";
                  padding: 4px;
                }

                span {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }

    .keyterms{
      display: none !important;
    }

    .keywords {
      height: fit-content;
      margin: 6px 6px;
      width: 95%;
      overflow-x: scroll;

      .left_blcok{
        display: none;
      }

      .key-scroll-wrapper{
        height: 100%;
        flex-wrap: nowrap;
        width: max-content;
        margin-top: 0px;

        .keywordBtn {
          margin-bottom: 6px;
          font-size: 12px;
        }
      }

      .key-scroll-wrapper::-webkit-scrollbar{
        height: 0 !important;
      }
    }::-webkit-scrollbar{
      height: 0px !important;
    }
  }
}

.w-450 {
  width: 450px;
}

.arrow_block {
  display: flex;
  .button {
    background: $White;
    border: 2px solid $Grey-3;
    border-radius: 3px;
    padding: 10px 6px;
    height: 40px;
    width: 40px;
    margin-bottom: 0;
  }
  .button:hover {
    background-color: $White;
    border: 2px solid $Purple-2;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    svg {
      path {
        fill: $Purple-2;
      }
    }
  }
  .button:focus {
    box-shadow: 0px 0px 15px 10px #9b99ff;
    background: #f6f6f8;
    border: none;
    outline: none;
    svg {
      path {
        fill: $Purple-1;
      }
    }
  }
  .button:active {
    background: #19afa7;
    box-shadow: none;
    border: none;
    outline: none;
    svg {
      path {
        fill: $White;
      }
    }
  }
}
