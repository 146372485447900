@import "../abstracts/variables";

label {
  line-height: 2 !important;
}
label.grid-container {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
input {
  padding: 0px 12px !important;
  border-radius: 5px !important;
  border: none !important;
  background: $color-grey-2;
  box-shadow: none !important;
  transition: none !important;
  color: $color-grey-7 !important;
  border: 2px solid transparent !important;
}
input::placeholder,
select::placeholder {
  color: $color-grey-5 !important;
  font-size: 16px !important;
}
input:focus,
textarea:focus {
  background: $color-grey-2 !important;
  border: 2px solid $color-purple-2 !important;
  color: $color-grey-7 !important;
}

select {
  height: 5rem;
  font-size: 1.6rem;
  background-color: #f0f3f6;
  border-radius: 3px;
  border: none;
  &:focus {
    border: 2px solid $color-purple-2;
  }
}

.checkmark {
  position: absolute;
  top: 7px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
input ~ .checkmark {
  background-color: #fff;
  border: 2px solid #7f8090;
}

/* When the checkbox is checked, add a blue background */
input:checked ~ .checkmark {
  background-color: #5551ff;
  border: 2px solid #5551ff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmark:after {
  left: 5px;
  top: 1.5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
